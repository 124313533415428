











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})

export default class HorizontalTabs extends Vue {
    @Prop() label!: string [];
    currentTab = 0;

    selectTab (index: number): void {
        if (this.currentTab !== index) {
            this.currentTab = index;
            this.$emit('tabChanged', this.currentTab);
        }
    }
}
