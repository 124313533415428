












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component

export default class TextInput extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop() maxLen!: number;
    content = '';

    valueChanged (): void {
        this.$emit('input', this.content);
        this.$emit('valueChanged');
    }

    @Watch('value', { immediate: true })
    onValueChanged (): void {
        this.content = this.value;
    }
}
