














































































import { Component, Vue } from 'vue-property-decorator';
import { network } from '@/services/network';
import { IGraphData, IStatResponse } from '@/services/statistics-if';
import { EValueRating } from '@/services/network-if';
import PageFrame from '@/components/page-frame.vue';
import AuthWidget from '@/components/auth.vue';
import BarGraph from '@/components/charts/bar-graph.vue';
import PieGraph from '@/components/charts/pie-graph.vue';
import ProxyDatePicker from '@/components/datepicker.vue';
import { Mutex } from 'async-mutex';
import { ttmConfig } from '@/services/config';

enum EScope {
    ALL,
    THIS_WEEK,
    THIS_MONTH,
    THIS_YEAR,
    SINCE
}

interface ITireCount {
    red: {
        count: number;
        percent: number;
    };
    yellow: {
        count: number;
        percent: number;
    };
    green: {
        count: number;
        percent: number;
    };
    notMeasured: {
        count: number;
        percent: number;
    };
    total: number;
}

@Component({ components: { PageFrame, AuthWidget, BarGraph, PieGraph, ProxyDatePicker } })

export default class StatisticsPage extends Vue {
    authSuccessful = false;
    EScope = EScope;
    scope = EScope.THIS_MONTH;
    treadValues: IGraphData [] = [];
    composition: IGraphData [] = [];
    updateChart = true;
    tireCount: ITireCount | null = null;
    pickerDate = new Date(Date.now());
    fetchMutex = new Mutex();
    readonly statusGreen = '#46C002';
    readonly statusRed = '#FF2800';
    readonly statusYellow = '#FDBF08';

    async mounted (): Promise<void> {
        window.addEventListener('resize',  this.update);
        this.pickerDate.setUTCHours(0, 0, 0, 0);
        await this.fetchData(this.thisMonth.toISOString());

    }

    unmounted (): void {
        window.removeEventListener('resize', this.update);
    }

    update (): void {
        this.updateChart = !this.updateChart;
    }

    async fetchData (since: string): Promise<void> {
        const release = await this.fetchMutex.acquire();

        this.tireCount = {
            red: { count: 0, percent: 0 },
            yellow: { count: 0, percent: 0 },
            green: { count: 0, percent: 0 },
            notMeasured: { count: 0, percent: 0 },
            total: 0
        };

        const recv = await network.apiRequest(`api/monitor/statistics?since=${since}`) as unknown as IStatResponse;

        if (recv && recv.treads && this.tireCount) {
            this.treadValues = [];

            for (const key in recv.treads) {
                const value = recv.treads[key];

                this.treadValues.push({
                    gLabel: key,
                    gValue: value.count,
                    gColor:this.barColor(value.rating)
                });

                switch (value.rating) {
                    case EValueRating.GOOD:
                        this.tireCount.green.count += value.count;
                        break;
                    case EValueRating.MARGINAL:
                        this.tireCount.yellow.count += value.count;
                        break;
                    case EValueRating.REPLACE:
                        this.tireCount.red.count += value.count;
                        break;
                }
            }

            this.tireCount.notMeasured.count = recv.notMeasured;
        }

        this.setTireCounts();
        this.updateChart = !this.updateChart;

        release();
    }

    setTireCounts (): void {
        this.composition = [];

        if (this.tireCount) {
            this.tireCount.total =
                this.tireCount.green.count + this.tireCount.red.count + this.tireCount.yellow.count + this.tireCount.notMeasured.count;

            if (this.tireCount.total > 0) {
                this.tireCount.notMeasured.percent = Math.round(100 * this.tireCount.notMeasured.count / this.tireCount.total);
                this.tireCount.red.percent = Math.round(100 * this.tireCount.red.count / this.tireCount.total);
                this.tireCount.yellow.percent = Math.round(100 * this.tireCount.yellow.count / this.tireCount.total);
                this.tireCount.green.percent = 100 - this.tireCount.notMeasured.percent - this.tireCount.red.percent - this.tireCount.yellow.percent;
            }

            this.composition.push({gLabel: 'good', gValue: this.tireCount.green.count, gColor: this.statusGreen});
            this.composition.push({gLabel: 'marginal', gValue: this.tireCount.yellow.count, gColor: this.statusYellow});
            this.composition.push({gLabel: 'replace', gValue: this.tireCount.red.count, gColor: this.statusRed});
            this.composition.push({gLabel: 'not measured', gValue: this.tireCount.notMeasured.count, gColor: 'lightgray'});
        }
    }

    async changeScope (scope: EScope): Promise<void> {
        this.scope = scope;

        switch (scope) {
            case EScope.SINCE:
                await this.fetchData(this.pickerDate.toISOString());
                break;
            case EScope.THIS_WEEK:
                await this.fetchData(this.thisWeek.toISOString());
                break;
            case EScope.THIS_MONTH:
                await this.fetchData(this.thisMonth.toISOString());
                break;
            case EScope.THIS_YEAR:
                await this.fetchData(this.thisYear.toISOString());
                break;
            case EScope.ALL:
                await this.fetchData('');
                break;
        }
    }

    barColor (rating: EValueRating): string {
        switch (rating) {
            case EValueRating.REPLACE:
                return this.statusRed;
            case EValueRating.MARGINAL:
                return this.statusYellow;
            case EValueRating.GOOD:
                return this.statusGreen;
            default:
                return 'white';
        }
    }

    async onDateSelected (dt: Date): Promise<void> {
        this.pickerDate = dt;
        this.pickerDate.setUTCHours(0, 0, 0, 0);
        if (this.scope === EScope.SINCE) {
            await this.fetchData(this.pickerDate.toISOString());
        }
    }

    get thisWeek (): Date {
        const dt = new Date(Date.now());
        const day = dt.getDay();
        let diff = 0;

        if (day === 0) {
            diff = 6;
        } else if (day > 1) {
            diff = day - 1;
        }

        dt.setDate(dt.getDate() - diff);
        dt.setUTCHours(0, 0, 0, 0);
        return dt;
    }

    get thisMonth (): Date {
        const dt = new Date(Date.now());
        dt.setDate(1);
        dt.setUTCHours(0, 0, 0, 0);
        return dt;
    }

    get thisYear (): Date {
        const dt = new Date(Date.now());
        dt.setFullYear(dt.getFullYear(), 0, 1);
        dt.setUTCHours(0, 0, 0, 0);
        return dt;
    }

    get sinceDate (): string {
        const formatter = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };
        const df = new Intl.DateTimeFormat(ttmConfig.locale, formatter);
        return `${df.format(this.pickerDate)}`;
    }
}
