










import { Component, Vue } from 'vue-property-decorator';
import PageFrame from '@/components/page-frame.vue';
import { ttmConfig } from '@/services/config';

@Component({
    components: {
        PageFrame,
    }
})

export default class HelpPage extends Vue {
    ifSource = ''

    mounted (): void {
        const lg = ttmConfig.language === 'de' ? 'de' : 'en';

        if (lg === 'en') {
            this.ifSource = `/doc/index.html`;
        } else {
            this.ifSource = `/doc/${lg}/index.html`;
        }
    }
}
