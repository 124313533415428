

























import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@/components/text-input.vue';
import { ttmConfig } from '@/services/config';
import { network } from '@/services/network';

@Component({
    components: { TextInput }
})

export default class WorkshopSetup extends Vue {
    @Ref('fileSelect') readonly fileSelect!: HTMLInputElement;
    company = '';
    address = '';
    city = '';
    web = '';
    email = '';
    imgSrc = '';
    wsChanged = false;
    ttmConfig = ttmConfig;
    initDone = false;
    readonly logoPath = '/api/monitor/workshop-logo';

    mounted (): void {
        this.company = ttmConfig.company;
        this.address = ttmConfig.address;
        this.city = ttmConfig.city;
        this.web = ttmConfig.web;
        this.email = ttmConfig.email;
        this.imgSrc = `${this.logoPath}?${Math.random()}`;

        setTimeout(() => { this.initDone = true; }, 100);
    }

    selectImg (): void {
        this.fileSelect.value = '';
        this.fileSelect.click();
    }

    async fileSelected (): Promise<void> {
        if (this.fileSelect.files) {
            const formData = new FormData();
            const name = this.fileSelect.files[0].name.endsWith('png') ? 'logo.png' : 'logo.jpg';
            formData.append('file', this.fileSelect.files[0], name);
            await network.apiUpload('/api/monitor/upload', formData);
            this.imgSrc = `${this.logoPath}?${Math.random()}`;
        }
    }

    async removeImg (): Promise<void> {
        await network.apiRequest('/api/monitor/workshop-logo-remove');
    }

    wsSave (): void {
        const data = [
            { key: 'company', value: this.company },
            { key: 'address', value: this.address },
            { key: 'city', value: this.city },
            { key: 'web', value: this.web },
            { key: 'email', value: this.email }
        ];

        ttmConfig.setWorkshopItems(data);
        this.wsChanged = false;
    }

    @Watch('company', { immediate: false })
    @Watch('address', { immediate: false })
    @Watch('city', { immediate: false })
    @Watch('web', { immediate: false })
    @Watch('email', { immediate: false })
    updateWorkshop (): void {
        if (this.initDone) {
            this.wsChanged = true;
        }
    }
}
