import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import _ from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any*/

Vue.use(VueI18n);

const dt12H = {
    short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    },
    long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
    }
};

const dt24H = {
    short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    },
    long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    }
};

export const dateTimeFormats = {
    cs: _.cloneDeep(dt24H),
    da: _.cloneDeep(dt24H),
    de: _.cloneDeep(dt24H),
    en: _.cloneDeep(dt12H),
    es: _.cloneDeep(dt24H),
    fi: _.cloneDeep(dt24H),
    fr: _.cloneDeep(dt24H),
    it: _.cloneDeep(dt24H),
    ja: _.cloneDeep(dt24H),
    lv: _.cloneDeep(dt24H),
    nl: _.cloneDeep(dt24H),
    no: _.cloneDeep(dt24H),
    pl: _.cloneDeep(dt24H),
    pt: _.cloneDeep(dt24H),
    ru: _.cloneDeep(dt24H),
    sv: _.cloneDeep(dt24H),
    tr: _.cloneDeep(dt24H),
    zh: _.cloneDeep(dt24H)
};

export function loadLocaleMessages (): LocaleMessages {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export const locales: {[index: string]:any} = {
    cs: 'CS - Česky',
    da: 'DA - Dansk',
    de: 'DE - Deutsch',
    en: 'EN - English',
    es: 'ES - Español',
    fi: 'FI - Suomi',
    fr: 'FR - Français',
    it: 'IT - Italiano',
    ja: 'JA - 日本人',
    lv: 'LV - Latviešu Valoda',
    nl: 'NL - Nederlands',
    no: 'NO - Norsk',
    pl: 'PL - Polski',
    pt: 'PT - Português',
    ru: 'RU - Русский',
    sv: 'SV - Svenska',
    tr: 'TR - Türkçe',
    zh: 'ZH - 中文'
};

export const localesRegion: {[index: string]:any} = {
    cs: ['cs-CZ'],
    da: ['da-DK'],
    de: ['de-DE', 'de-AT', 'de-CH'],
    en: ['en-GB', 'en-AU', 'en-CA', 'en-US'],
    es: ['es-ES'],
    fi: ['fi-FI'],
    fr: ['fr-FR', 'fr-BE', 'fr-CA', 'fr-CH'],
    it: ['it-IT', 'it-CH'],
    ja: ['ja-JP'],
    lv: ['lv-LV'],
    nl: ['nl-NL'],
    no: ['no-NO'],
    pl: ['pl-PL'],
    pt: ['pt-PT', 'pt-BR'],
    ru: ['ru-RU'],
    sv: ['sv-SE'],
    tr: ['tr-TR'],
    zh: ['zh-CN', 'zh-TW']
};

export const tzOffsets = [
    "-12:00",
    "-11:00",
    "-10:00",
    "-09:30",
    "-09:00",
    "-08:00",
    "-07:00",
    "-06:00",
    "-05:00",
    "-04:00",
    "-03:30",
    "-03:00",
    "-02:30",
    "-02:00",
    "-01:00",
    "+00:00",
    "+01:00",
    "+02:00",
    "+03:00",
    "+03:30",
    "+04:00",
    "+04:30",
    "+05:00",
    "+05:30",
    "+05:45",
    "+06:00",
    "+06:30",
    "+07:00",
    "+08:00",
    "+08:30",
    "+08:45",
    "+09:00",
    "+09:30",
    "+10:00",
    "+10:30",
    "+11:00",
    "+12:00",
    "+12:45",
    "+13:00",
    "+13:45",
    "+14:00"
];
