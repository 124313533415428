
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import backIcon from '@/assets/svg/back.svg?inline';
import printIcon from '@/assets/svg/print.svg?inline';
import downloadIcon from '@/assets/svg/download.svg?inline';
import maxPage from '@/assets/svg/maxpage.svg?inline';
import fitPage from '@/assets/svg/fitpage.svg?inline';
import printJS from 'print-js';

@Component({
    components: { backIcon, printIcon, downloadIcon, maxPage, fitPage }
})

export default class ReportPreview extends Vue {
    @Prop() reportTimestamp!: string;
    @Prop() reportPlate!: string;
    svgUrl = '';
    pdfUrl = '';
    maxpage = true;

    doPrint (): void {
        if (this.svgUrl) {
            printJS(this.svgUrl, 'image');
        }
    }

    downloadPdf (): void {
        if (this.pdfUrl) {
            const name = `${this.createFileName(this.reportTimestamp, this.reportPlate)}.pdf`;
            const a = document.createElement('a');
            a.href = this.pdfUrl;
            a.setAttribute('download', name);
            a.click();
        }
    }

    createFileName (timestamp: string, plate: string): string {
        const regex = /([^a-zA-Z0-9])/gm;
        const ts = timestamp.replace(regex, '');
        const pl = plate.replace(regex, '');
        return `${ts}_${pl}`;
    }

    @Watch('reportTimestamp', { immediate: true })
    update (): void {
        if (this.reportTimestamp) {
            this.svgUrl = `api/monitor/report?timestamp=${this.reportTimestamp}`;
            this.pdfUrl = `api/monitor/report?timestamp=${this.reportTimestamp}&pdf`;
        }
    }
}
