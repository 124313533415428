








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component

export default class TextLink extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop() noLink!: boolean;

    clicked (): void {
        this.$emit('linkClicked');
    }
}
