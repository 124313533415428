






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EValueRating, IProfileScheme } from '@/services/network-if';

interface IBarProperties {
    orgX: number;
    orgY: number;
    orgWidth: number;
    orgHeight: number;
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
    position: string;
}

@Component

export default class TireScheme extends Vue {
    @Prop() profile!: IProfileScheme;
    contour = { x: 0, y: 0, width: 0, height: 0 };
    barProps: IBarProperties [] = [];
    readonly imgWidth = 1134;
    readonly imgHeight = 314;
    readonly fullHeight = 10;

    mounted (): void {
        this.initBars();
        this.draw();
    }

    draw (): void {
        const container = this.$refs['scheme-container'] as HTMLDivElement;
        const canvas = this.$refs['scheme-canvas'] as HTMLCanvasElement;
        const context = canvas.getContext('2d');

        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;

        this.calcDimensions(container.clientWidth, container.clientHeight);

        for (const bar of this.barProps) {
            if (bar.height > 0) {
                this.drawRect(context, bar.x, bar.y, bar.width, bar.height, 1, bar.color, true);
            }
        }
    }

    calcDimensions (boxW: number, boxH: number): void {
        this.contour.width = boxW;
        this.contour.height = Math.floor(this.imgHeight * this.contour.width / this.imgWidth);

        if (this.contour.height > boxH) {
            this.contour.height = boxH;
            this.contour.width = Math.floor(this.imgWidth * this.contour.height / this.imgHeight);
        }
        if (boxW > this.contour.width) {
            this.contour.x = Math.floor((boxW - this.contour.width) / 2);
        }
        if (boxH > this.contour.height) {
            this.contour.y = Math.floor((boxH - this.contour.height) / 2);
        }

        this.adjustBars();
    }

    initBars (): void {
        this.barProps.push({
            orgX: 207,
            orgY: 244,
            orgWidth: 48,
            orgHeight: 236,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            color: 'white',
            position: 'left'
        });
        this.barProps.push({
            orgX: 309,
            orgY: 244,
            orgWidth: 122,
            orgHeight: 236,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            color: 'white',
            position: 'left'
        });
        this.barProps.push({
            orgX: 486,
            orgY: 244,
            orgWidth: 166,
            orgHeight: 236,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            color: 'white',
            position: 'middle'
        });
        this.barProps.push({
            orgX: 706,
            orgY: 244,
            orgWidth: 122,
            orgHeight: 236,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            color: 'white',
            position: 'right'
        });
        this.barProps.push({
            orgX: 882,
            orgY: 244,
            orgWidth: 48,
            orgHeight: 236,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            color: 'white',
            position: 'right'
        });
    }

    adjustBars (): void {
        if (this.profile) {
            for (const bar of this.barProps) {
                let heightPortion;
                if (bar.position === 'left') {
                   if (isNaN(this.profile.left.value)) {
                       bar.height = 0;
                       continue;
                   }
                   heightPortion = this.profile.left.value / this.fullHeight;
                   bar.color = this.getColor(this.profile.left.rating);
                } else if (bar.position === 'middle') {
                    if (isNaN(this.profile.middle.value)) {
                        bar.height = 0;
                        continue;
                    }
                    heightPortion = this.profile.middle.value / this.fullHeight;
                    bar.color = this.getColor(this.profile.middle.rating);
                } else {
                    if (isNaN(this.profile.right.value)) {
                        bar.height = 0;
                        continue;
                    }
                    heightPortion = this.profile.right.value / this.fullHeight;
                    bar.color = this.getColor(this.profile.right.rating);
                }

                bar.width = Math.floor((this.contour.width * bar.orgWidth / this.imgWidth));
                bar.height = Math.floor((this.contour.height * bar.orgHeight / this.imgHeight) * heightPortion);
                bar.x = Math.floor((this.contour.width * bar.orgX / this.imgWidth)) + this.contour.x;
                bar.y = Math.floor((this.contour.height * bar.orgY / this.imgHeight)) + this.contour.y - bar.height;
            }
        }
    }

    drawRect (
        context: CanvasRenderingContext2D | null, xPos: number, yPos:number,
        width: number, height: number, lineWidth: number, color: string, filled: boolean): void {
        if (context) {
            context.beginPath();
            context.moveTo(xPos, yPos);
            context.lineTo(xPos + width, yPos);
            context.lineTo(xPos + width, yPos + height);
            context.lineTo(xPos, yPos + height);
            context.lineTo(xPos, yPos);

            context.lineWidth = lineWidth;
            context.strokeStyle = color;

            if (filled) {
                context.fillStyle = color;
                context.fill();
            }

            context.stroke();
            context.closePath();
        }
    }

    getColor (rating: EValueRating): string {
        switch (rating) {
            case EValueRating.GOOD:
                return '#46C002';
            case EValueRating.MARGINAL:
                return '#FDAF08';
            case EValueRating.REPLACE:
                return '#FF0000';
            default:
                return 'white';
        }
    }

    @Watch('profile')
    update (): void {
        this.draw();
    }
}
