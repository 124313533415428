































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ttmConfig } from '@/services/config';
import statusOk from '@/assets/svg/status-ok.svg?inline';
import statusMarginal from '@/assets/svg/status-marginal.svg?inline';
import statusReplace from '@/assets/svg/status-replace.svg?inline';
import { ETirePosition, EValueRating, IEvaluatedTireSet } from '@/services/network-if';
import { Fn } from '@/services/fn';

@Component({
    components: {
        statusOk,
        statusMarginal,
        statusReplace
    }
})

export default class BasicTreadValue extends Vue {
    @Prop() position!: ETirePosition;
    @Prop() posText!: string;
    @Prop() tireSet!: IEvaluatedTireSet;
    @Prop() liteSkin!: boolean;
    valueStr = '';
    unitStr = '';
    valueRating: EValueRating = EValueRating.NEUTRAL;
    ETirePosition = ETirePosition;
    EValueRating = EValueRating;

    mounted (): void {
        this.unitStr = (ttmConfig.measUnit === 'mm' ? 'mm' : 'inch/32');
    }

    valueClass (): { neutral: boolean, good: boolean, marginal: boolean, replace: boolean} {
        return {
            neutral: this.valueRating === EValueRating.NEUTRAL,
            good: this.valueRating === EValueRating.GOOD,
            marginal: this.valueRating === EValueRating.MARGINAL,
            replace: this.valueRating === EValueRating.REPLACE
        };
    }

    @Watch('tireSet', { immediate: true })
    update (): void {
        if (this.tireSet) {
            this.valueStr = Fn.formatTread(this.tireSet.minimumValue.value, ttmConfig.measUnit);
            this.valueRating = this.tireSet.minimumValue.rating;

            if (this.tireSet.deepGroove) {
                this.valueStr = `> ${parseFloat(this.valueStr).toFixed(0)}`;
            }
        }
    }
}
