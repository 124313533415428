import { EValueRating, IEvaluatedVehicleSet } from '@/services/network-if';

export enum EHint {
    None,
    WheelAlignment,
    TirePressure,
    All
}

export enum ELaneStatus {
    IDLE,
    BUSY,
    CONNECTED,
    DISCONNECTED,
    ERROR,
    DATA
}

export interface IMonitorTread {
    value: number;
    rating: EValueRating;
    deepGroove: boolean;
}

export interface IMonitorRecord {
    timestamp: string;
    measplace: string;
    customer: string;
    vehicle: string;
    frontLeft: IMonitorTread;
    frontRight: IMonitorTread;
    rearLeft: IMonitorTread;
    rearRight: IMonitorTread;
    vehicleRating: EValueRating;
    crossingDirection: string;
    hint: EHint;
}

export interface ILaneData {
    lastCrossingStatus: number;
    status: ELaneStatus;
    statusCode: number;
    data: IEvaluatedVehicleSet | null;
    ipAddr: string;
}
