















import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';

@Component

export default class TextInput extends Vue {
    @Prop() label!: string;
    @Model('change', { type: String }) ip!: string;
    firstSeg = 0;
    secondSeg = 0;
    thirdSeg = 0;
    forthSeg = 0;

    get ipStr (): string {
        const a = parseInt(this.firstSeg.toString());
        const b = parseInt(this.secondSeg.toString());
        const c = parseInt(this.thirdSeg.toString());
        const d = parseInt(this.forthSeg.toString());
        return `${a}.${b}.${c}.${d}`;
    }

    onApply (): void {
        this.$emit('apply', this.ipStr);
    }

    @Watch('ip', { immediate: true })
    ipChanged (): void {
        if (this.ip) {
            const parts = this.ip.split('.');
            const segments = 4;
            if (parts.length === segments) {
                this.firstSeg = parseInt(parts[0]);
                this.secondSeg = parseInt(parts[1]);
                this.thirdSeg = parseInt(parts[2]);
                this.forthSeg = parseInt(parts[3]);
            }
        }
    }

    @Watch('firstSeg', { immediate: false })
    validateFirst (): void {
        if (this.firstSeg < 0 || this.firstSeg > 255 || (this.firstSeg - Math.floor(this.firstSeg)) !== 0) {
            this.firstSeg = 0;
        }
        this.$emit('change', this.ipStr);
    }

    @Watch('secondSeg', { immediate: false })
    validateSecond (): void {
        if (this.secondSeg < 0 || this.secondSeg > 255 || (this.secondSeg - Math.floor(this.secondSeg)) !== 0) {
            this.secondSeg = 0;
        }
        this.$emit('change', this.ipStr);
    }

    @Watch('thirdSeg', { immediate: false })
    validateThird (): void {
        if (this.thirdSeg < 0 || this.thirdSeg > 255 || (this.thirdSeg - Math.floor(this.thirdSeg)) !== 0) {
            this.thirdSeg = 0;
        }
        this.$emit('change', this.ipStr);
    }

    @Watch('forthSeg', { immediate: false })
    validateForth (): void {
        if (this.forthSeg < 0 || this.forthSeg > 255 || (this.forthSeg - Math.floor(this.forthSeg)) !== 0) {
            this.forthSeg = 0;
        }
        this.$emit('change', this.ipStr);
    }
}
