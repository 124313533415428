








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component

export default class UpDownInput extends Vue {
    @Prop() maxVal!: number;
    @Prop() minVal!: number;
    @Prop() step!: number;
    @Prop() value!: number;
    @Prop() isWrong!: boolean;
    @Prop() isEnabled!: boolean;
    currentVal = 0;
    valueStr = '';

    increment (): void {
        if (this.currentVal < this.maxVal) {
            this.currentVal += this.step;
            this.$emit('changed', this.currentVal);
        }
    }

    decrement (): void {
        if (this.currentVal > this.minVal) {
            this.currentVal -= this.step;
            this.$emit('changed', this.currentVal);
        }
    }

    setValue (): void {
        if ((this.currentVal - Math.floor(this.currentVal)) !== 0) {
            this.valueStr = this.currentVal.toFixed(1);
        } else {
            this.valueStr = this.currentVal.toFixed(0);
        }
    }

    @Watch('value', { immediate: true })
    update (): void {
        this.currentVal = this.value;
        this.setValue();
    }
}
