




















import { Component, Vue } from 'vue-property-decorator';
import vueJsonEditor from 'vue-json-editor';
import { network } from '@/services/network';
import { ttmConfig } from '@/services/config';

/* eslint-disable @typescript-eslint/no-explicit-any*/

@Component({ components: { vueJsonEditor } })

export default class ExpertEdit extends Vue {
    json = { };
    changed = false;
    hasReceived = false;
    ttmConfig = ttmConfig;

    async mounted (): Promise<void> {
        const recv = await network.apiRequest('/api/monitor/get-raw-config');
        const config = recv ?? null;

        if (config) {
            this.json = config as any;
            this.hasReceived = true;
        }
    }

    save (): void {
        if (this.json && this.changed && this.hasReceived) {
            network.apiPost('/api/monitor/set-raw-config', this.json).then(() => {
                // NOTHING TO DO
            });
            this.changed = false;
        }
    }
}
