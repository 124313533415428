




import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Chart, registerables, ChartType } from 'chart.js';
import { ttmConfig } from '@/services/config';
import { IGraphData, IBarGraphData } from '@/services/statistics-if';

@Component({ components: { } })
export default class BarGraph extends Vue {
    @Prop() graphData!: IGraphData [];
    @Prop() updateChart!: boolean;
    chart: Chart | null = null;

    mounted () : void {
        Chart.register(...registerables);
        this.drawChart();
    }

    setData (): IBarGraphData {
        const barLabels : string [] = [];
        const barValues: number [] = [];
        const barColors: string [] = [];
        for (const item of this.graphData) {
            barLabels.push(item.gLabel);
            barValues.push(item.gValue);
            barColors.push(item.gColor);
        }

        return {
            labels: barLabels,
            datasets: [{
                data: barValues,
                backgroundColor: barColors,
            }]
        };
    }

    updateData (): void {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
            this.drawChart();
        }
    }

    drawChart (): void {
        const config = {
            type: 'bar' as ChartType,
            data: this.setData(),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: this.$t('statistics.tireTreads') as string,
                        color: 'white',
                        font: {
                            size: 16
                        }
                    },
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    xAxis: {
                        grid: {
                            color: 'rgba(255,255,255,0.25)',
                            borderColor: 'rgba(255,255,255,0.25)'
                        },
                        ticks: {
                            color: 'white'
                        },
                        title: {
                            display: true,
                            text: ttmConfig.measUnit === 'mm' ? 'mm' : 'inch/32',
                            color: 'white'
                        }
                    },
                    yAxis: {
                        grid: {
                            color: 'rgba(255,255,255,0.25)',
                            borderColor: 'rgba(255,255,255,0.25)'
                        },
                        ticks: {
                            color: 'white'
                        },
                        title: {
                            display: true,
                            text: this.$t('statistics.tireCount') as string,
                            color: 'white'
                        }
                    },
                }
            }
        };

        this.chart = new Chart('statbar', config);
    }

    @Watch('updateChart', { immediate: true })
    update () : void {
        this.updateData();
    }
}
