







































import { Component, Ref, Vue } from 'vue-property-decorator';
import { network } from '@/services/network';
import Spinner from '@/components/spinner.vue';
import { ESocketStatus, ISocketMessage } from '@/services/network-if';

@Component({
    components: { Spinner }
})

export default class SoftwareUpdate extends Vue {
    @Ref('zipSelect') readonly zipSelect!: HTMLInputElement;
    selected = { status: false, info: '' };
    installed = { status: false, info: '' };
    showSpinner = false;
    platform = '';

    async mounted (): Promise<void> {
        network.setWebsocketCallback(this.sockReceiver.bind(this));
        const recv = await network.proxyVersion();
        this.platform = recv?.platform ?? '';
    }

    selectPackage (): void {
        this.zipSelect.value = '';
        this.zipSelect.click();
    }

    zipSelected (): void {
        if (this.zipSelect.files) {
            this.selected.status = true;
            this.selected.info = this.zipSelect.files[0].name;
        }
    }

    async install (): Promise<void> {
        if (this.zipSelect.files) {
            this.installed.info = '';
            this.showSpinner = true;
            const formData = new FormData();
            formData.append('file', this.zipSelect.files[0], this.zipSelect.files[0].name);
            await network.apiUpload('/api/monitor/software-update', formData);
        }
    }

    async sockReceiver (data: string): Promise<void> {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;
            if (msg.status === ESocketStatus.CONNECTION_OPENED) {
                this.showSpinner = false;
                window.location.reload();
            } else if (msg.status === ESocketStatus.SETUP_CANCELLED) {
                this.showSpinner = false;
                this.installed.status = false;
                this.installed.info = this.$t('actionCanceled') as string;
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    downloadDb (): void {
        const regex = /([^a-zA-Z0-9])/gm;
        const ts = new Date().toISOString().replace(regex, '');
        const name = `${ts}_database_backup.zip`;
        const a = document.createElement('a');
        a.href = '/api/monitor/backup';
        a.setAttribute('download', name);
        a.click();
    }
}
