






import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Chart, registerables, ChartType } from 'chart.js';
import { IGraphData, IBarGraphData } from '@/services/statistics-if';

@Component({ components: { } })
export default class PieGraph extends Vue {
    @Prop() graphData!: IGraphData [];
    @Prop() updateChart!: boolean;
    chart: Chart | null = null;

    mounted () : void {
        Chart.register(...registerables);
        this.drawChart();
    }

    setData (): IBarGraphData {
        const pieLabels : string [] = [];
        const pieValues: number [] = [];
        const pieColors: string [] = [];

        for (const item of this.graphData) {
            pieLabels.push(item.gLabel);
            pieValues.push(item.gValue);
            pieColors.push(item.gColor);
        }

        return {
            labels: pieLabels,
            datasets: [{
                data: pieValues,
                backgroundColor: pieColors,
            }]
        };
    }

    updateData (): void {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
            this.drawChart();
        }
    }

    drawChart (): void {
        const config = {
            type: 'doughnut' as ChartType,
            data: this.setData(),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Reifenanzahl',
                        color: 'white',
                        font: {
                            size: 16
                        }
                    }
                }
            }
        };

        this.chart = new Chart('statpie', config);
    }

    @Watch('updateChart', { immediate: true })
    update () : void {
        this.updateData();
    }
}
