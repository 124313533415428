

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ExpertTreadValue from '@/components/measview/expert-tread-value.vue';
import { ETirePosition, EValueRating, IEvaluatedVehicleSet, WPStr } from '@/services/network-if';
import checkAlignmentWhite from '@/assets/svg/check-alignment-white.svg?inline';
import checkPressureWhite from '@/assets/svg/check-pressure-white.svg?inline';
import replaceTireWhite from '@/assets/svg/replace-tire-white.svg?inline';
import limitWarningWhite from '@/assets/svg/limit-warning-white.svg?inline';
import checkAlignment from '@/assets/svg/check-alignment.svg?inline';
import checkPressure from '@/assets/svg/check-pressure.svg?inline';
import replaceTire from '@/assets/svg/replace-tire.svg?inline';
import limitWarning from '@/assets/svg/limit-warning.svg?inline';
import { ttmConfig } from '@/services/config';

@Component({
    components: {
        ExpertTreadValue,
        checkAlignmentWhite,
        checkPressureWhite,
        replaceTireWhite,
        limitWarningWhite,
        checkAlignment,
        checkPressure,
        replaceTire,
        limitWarning
    }
})

export default class ExpertView extends Vue {
    @Prop() evalData!: IEvaluatedVehicleSet;
    ETirePosition = ETirePosition;
    showReplace = false;
    showPressureCheck = false;
    showAlignmentCheck = false;
    showLimitWarning = false;
    liteSkin = true;

    mounted (): void {
        this.liteSkin = ttmConfig.liteSkin;
    }

    @Watch('evalData', { immediate: true })
    update (): void {
        if (this.evalData) {
            this.showReplace = this.evalData.vehicleRating === EValueRating.REPLACE;
            this.showLimitWarning = this.evalData.vehicleRating === EValueRating.MARGINAL;
            this.showPressureCheck = this.pressureCheckNeeded();
            this.showAlignmentCheck = this.alignmentCheckNeeded();
        }
    }

    pressureCheckNeeded (): boolean {
        return (
            this.evalData.frontLeft.wearPattern === WPStr.parabolicWearCentered ||
            this.evalData.frontLeft.wearPattern === WPStr.parabolicWearOutside ||
            this.evalData.frontRight.wearPattern === WPStr.parabolicWearCentered ||
            this.evalData.frontRight.wearPattern === WPStr.parabolicWearOutside ||
            this.evalData.rearLeft.wearPattern === WPStr.parabolicWearCentered ||
            this.evalData.rearLeft.wearPattern === WPStr.parabolicWearOutside ||
            this.evalData.rearRight.wearPattern === WPStr.parabolicWearCentered ||
            this.evalData.rearRight.wearPattern === WPStr.parabolicWearOutside
        );
    }

    alignmentCheckNeeded (): boolean {
        return (
            this.evalData.frontLeft.wearPattern === WPStr.linearWearLeft ||
            this.evalData.frontLeft.wearPattern === WPStr.linearWearRight ||
            this.evalData.frontRight.wearPattern === WPStr.linearWearLeft ||
            this.evalData.frontRight.wearPattern === WPStr.linearWearRight ||
            this.evalData.rearLeft.wearPattern === WPStr.linearWearLeft ||
            this.evalData.rearLeft.wearPattern === WPStr.linearWearRight ||
            this.evalData.rearRight.wearPattern === WPStr.linearWearLeft ||
            this.evalData.rearRight.wearPattern === WPStr.linearWearRight
        );
    }

    getTr(id: string): string {
        return this.$t(id) as string;
    }
}
