

















































































import { Component, Vue } from 'vue-property-decorator';
import { ttmConfig } from '@/services/config';
import UpDownInput from '@/components/updown-input.vue';
import OnOffSwitch from '@/components/onoff-switch.vue';
import { IStation, EViewType } from '@/services/config-if';

@Component({
    components: {
        UpDownInput,
        OnOffSwitch,
    }
})

export default class AppearanceSetup extends Vue {
    showLane = true;
    hideDelay = 0;
    hidePlates = false;
    hideValues = false;
    valueDuration = 0;
    ttmConfig = ttmConfig;
    EViewType = EViewType;
    liteSkin = false;
    viewType = EViewType.Expert;

    mounted (): void {
        this.showLane = ttmConfig.showMonitorLane;
        this.hideDelay = ttmConfig.vehicleEntrysHideDelay;
        this.hidePlates = (this.hideDelay > 0);
        this.valueDuration = ttmConfig.resultsValueDuration;
        this.hideValues = (this.valueDuration > 0);
        this.viewType = ttmConfig.viewType;
        this.liteSkin = ttmConfig.liteSkin;
    }

    changeShowLane (): void {
        this.showLane = !this.showLane;
        ttmConfig.setShowMonitorLane(this.showLane, false);
    }

    onHidePlates (): void {
        this.hidePlates = !this.hidePlates;
        this.hideDelay = this.hidePlates ? 1 : 0;
        ttmConfig.setVehicleEntrysHideDelay(this.hideDelay, false);
    }

    setHideDelay (value: number): void {
        if (this.hidePlates) {
            this.hideDelay = value;
            ttmConfig.setVehicleEntrysHideDelay(this.hideDelay, false);
        }
    }

    onHideValues (): void {
        this.hideValues = !this.hideValues;
        this.valueDuration = this.hideValues ? 15 : 0;
        ttmConfig.setResultsViewDuration(this.valueDuration, false);
    }

    setValueDuration (value: number): void {
        if (this.hideValues) {
            this.valueDuration = value;
            ttmConfig.setResultsViewDuration(this.valueDuration, false);
        }
    }

    saveDefault (): void {
        ttmConfig.setShowMonitorLane(this.showLane, true);
        ttmConfig.setVehicleEntrysHideDelay(this.hideDelay, true);
        ttmConfig.setResultsViewDuration(this.valueDuration, true);
        ttmConfig.setResultsViewDuration(this.valueDuration, true);
        ttmConfig.setViewType(this.viewType, true);
        ttmConfig.setLiteSkin(this.liteSkin, true);
    }

    setViewType(viewType: EViewType): void {
        this.viewType = viewType;
        ttmConfig.setViewType(this.viewType, false);
    }

    setLiteSkin(liteSkin: boolean): void {
        this.liteSkin = liteSkin;
        ttmConfig.setLiteSkin(this.liteSkin, false);
    }

    get moduleStations (): {[p: string]: IStation} | undefined {
        const stations: {[p: string]: IStation} = {};

        for (const ip in ttmConfig.stations) {
            if (ttmConfig.stations[ip].easyTread) {
                stations[ip] = ttmConfig.stations[ip];
            }
        }

        return stations;
    }
}
