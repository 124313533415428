import Vue from 'vue';
import App from './App.vue';
import { router } from '@/router/router';
import { i18nTTM as i18n } from '@/services/i18n-ttm';
import { store } from '@/store';
import Vue2TouchEvents from  'vue2-touch-events';
import VueCookies from 'vue-cookies';
import VueIframe from 'vue-iframes'

Vue.config.productionTip = false;

Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: '',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 100,
    longTapTimeInterval: 400
});

Vue.use(VueIframe)

Vue.use(VueCookies)
Vue.$cookies.config('1000d')

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
