











import { Prop, Component, Vue } from 'vue-property-decorator';
import DatePicker from 'vuejs-datepicker';
import { cs, da, de, en, es, fi, fr, it, ja, lv, nl, no, pl, pt, ru, sv, tr, zh } from 'vuejs-datepicker/dist/locale'
import { ttmConfig } from '@/services/config';
import CalendarIcon from '@/assets/svg/opencal.svg?inline';

@Component({ components: { DatePicker, CalendarIcon } })
export default class ProxyDatePicker extends Vue {
    @Prop() initDate!: Date;
    ttmConfig = ttmConfig;

    get language (): string {
        if (ttmConfig.language === 'cs') {
            return cs;
        } else if (ttmConfig.language === 'da') {
            return da;
        } else if (ttmConfig.language === 'de') {
            return de;
        } else if (ttmConfig.language === 'es') {
            return es;
        } else if (ttmConfig.language === 'fi') {
            return fi;
        } else if (ttmConfig.language === 'fr') {
            return fr;
        } else if (ttmConfig.language === 'it') {
            return it;
        } else if (ttmConfig.language === 'ja') {
            return ja;
        } else if (ttmConfig.language === 'lv') {
            return lv;
        } else if (ttmConfig.language === 'nl') {
            return nl;
        } else if (ttmConfig.language === 'no') {
            return no;
        } else if (ttmConfig.language === 'pl') {
            return pl;
        } else if (ttmConfig.language === 'pt') {
            return pt;
        } else if (ttmConfig.language === 'ru') {
            return ru;
        } else if (ttmConfig.language === 'sv') {
            return sv;
        } else if (ttmConfig.language === 'tr') {
            return tr;
        } else if (ttmConfig.language === 'zh') {
            return zh;
        }
        return en;
    }

    dateSelected (dt: Date): void {
        this.$emit('dateSelected', dt);
    }
}
