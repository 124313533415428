






import { Component, Vue } from 'vue-property-decorator';
import { paths } from '@/router/paths';
import { network } from '@/services/network';
import { ttmConfig } from '@/services/config';

@Component
export default class App extends Vue {
    paths = paths;

    async created (): Promise<void> {
        await ttmConfig.load();
        network.startWebsocket();
    }
}
