






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
    @Prop() message!: string;
}
