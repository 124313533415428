





































































import { Component, Vue } from 'vue-property-decorator';
import { network } from '@/services/network';
import OnOffSwitch from '@/components/onoff-switch.vue';
import { ESocketStatus, ISocketMessage } from '@/services/network-if';
import { ttmConfig } from '@/services/config';
import ReloadIcon from '@/assets/svg/reload.svg?inline';

enum EAgentStatus {
    GREEN,
    YELLOW,
    RED
}

interface IOrderAgentCheck {
    status: EAgentStatus;
    version: string;
    ip: string;
    connection: string;
    installation: string;
}

@Component({
  components: { OnOffSwitch, ReloadIcon }
})

export default class InterfacesSetup extends Vue {
    oaInfo: IOrderAgentCheck = {
        status: EAgentStatus.RED,
        version: '',
        ip: '',
        connection: '',
        installation: ''
    };

    autoStart = false;
    asanetwork = false;
    programName = 'OrderAgent';
    EAgentStatus = EAgentStatus;
    searching = true;

    async mounted (): Promise<void> {
        this.autoStart = ttmConfig.OrderAgentAutoStart;
        this.asanetwork = ttmConfig.AsaNetworkEnabled;
        network.setWebsocketCallback(this.sockReceiver.bind(this));
        await network.apiRequest('api/monitor/check-order-agent');
    }

    status (status: EAgentStatus): string {
        switch (status) {
            case EAgentStatus.GREEN:
                return 'green';
            case EAgentStatus.YELLOW:
                return 'yellow';
            default:
                return 'red';
        }
    }

    statusText (status: EAgentStatus): string {
        switch (status) {
            case EAgentStatus.GREEN:
                return this.$t('orderAgent.status.running') as string;
            case EAgentStatus.YELLOW:
                return this.$t('orderAgent.status.notRunning') as string ;
            default:
                return this.$t('orderAgent.status.notAvailable') as string;
        }
    }

    async switchAutoStart (): Promise<void> {
        this.autoStart = !this.autoStart;
        ttmConfig.setOrderAgentAutoStart(this.autoStart);

        if (this.autoStart) {
            this.searching = true;
            this.resetStatus();

            await network.apiRequest('api/monitor/process-order-agent?task=start');
            this.checkForStart().then(() => {
                // DONE
            });
        } else {
            this.searching = true;
            this.oaInfo.status = EAgentStatus.YELLOW;
            this.oaInfo.ip = '';
            this.oaInfo.connection = '';
            await network.apiRequest('api/monitor/process-order-agent?task=stop');
            this.searching = false;
        }
    }

    switchAsa () : void {
        this.asanetwork = !this.asanetwork;
        ttmConfig.setAsaNetworkEnabled(this.asanetwork);
    }

    async checkForStart (): Promise<void> {
        while (this.searching) {
            await network.apiRequest('api/monitor/check-order-agent');
            await new Promise((resolve) => setTimeout(resolve, 250));
        }
    }

    async sockReceiver (data: string): Promise<void> {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;
            if (msg.status === ESocketStatus.ORDERAGENT_RESULT) {
                this.oaInfo = msg.data;
                this.searching = false;
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    async reload (): Promise<void> {
        this.resetStatus();
        this.searching = true;
        await network.apiRequest('api/monitor/check-order-agent');
    }

    resetStatus () : void {
        this.oaInfo.status = EAgentStatus.RED;
        this.oaInfo.version = '';
        this.oaInfo.ip = '';
        this.oaInfo.connection = '';
        this.oaInfo.installation = '';
    }
}
