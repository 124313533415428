

















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageFrame from '@/components/page-frame.vue';
import { ttmConfig } from '@/services/config';
import movePicture from '@/assets/svg/background-ready.svg?inline';
import stopPicture from '@/assets/svg/background-stop.svg?inline';
import unevenHint from '@/assets/svg/uneven.svg?inline';
import speedHint from '@/assets/svg/speed.svg?inline';
import { network } from '@/services/network';
import { ESocketStatus, IEvaluatedVehicleSet, ISocketMessage } from '@/services/network-if';
import _ from 'lodash';
import { ELaneStatus, ILaneData } from '@/services/monitor-if';
import ExpertView from '@/components/measview/expert-view.vue';
import StandardView from '@/components/measview/standard-view.vue';
import BasicView from '@/components/measview/basic-view.vue';
import { EViewType } from '@/services/config-if';
import { paths } from '@/router/paths';

/* eslint-disable @typescript-eslint/no-explicit-any*/

    @Component({
        components: {
            PageFrame,
            movePicture,
            stopPicture,
            unevenHint,
            speedHint,
            ExpertView,
            StandardView,
            BasicView
        }
    })

    export default class CrossingPage extends Vue {
        laneInfo = { ipAddr: '', name: '' };
        laneStatus = ELaneStatus.IDLE;
        errorMessage = '';
        measData: IEvaluatedVehicleSet | null = null;
        ELaneStatus = ELaneStatus;
        waitForData = false;
        activePage = EViewType.Expert;
        EViewType = EViewType;
        speedLimit = '';
        paths = paths;

        async mounted (): Promise<void> {
            await ttmConfig.load();
            this.laneChanged();
        }

        async sockReceiver (data: string): Promise<void> {
            try {
                const msg = JSON.parse(data) as unknown as ISocketMessage;

                switch (msg.status) {
                    case ESocketStatus.CONNECTION_OPENED:
                        this.errorMessage = '';
                        break;
                    case ESocketStatus.CONNECTION_LOST:
                        this.errorMessage = this.$t('monitor.connectionProxyLost') as string;
                        break;
                    case ESocketStatus.CONNECTION_STATUS_MODULE: {
                        this.processConnectionStatus(msg.data as ILaneData);
                        break;
                    }
                    case ESocketStatus.CONFIGURATION_CHANGED:
                        // TODO: RECALCULATE RESULTS
                        break;
                }
            } catch (e) {
                // invalid data -> will not processed
            }
        }

        processConnectionStatus(data: ILaneData): void {
            if (data.ipAddr === this.laneInfo.ipAddr || data.ipAddr === this.onlyIp) {
                switch (data.status) {
                    case ELaneStatus.BUSY:
                        this.laneStatus = ELaneStatus.BUSY;
                        setTimeout(() => { this.laneStatus = ELaneStatus.IDLE; }, 60000);
                        break;
                    case ELaneStatus.CONNECTED:
                        this.errorMessage = '';
                        break;
                    case ELaneStatus.DISCONNECTED:
                        this.errorMessage = this.$t('monitor.connectionCurrentModuleLost') as string;
                        break;
                    case ELaneStatus.ERROR:
                        // TODO: SHOW ERROR MESSAGE
                        break;
                    case ELaneStatus.DATA:
                        this.measData = _.cloneDeep(data.data);
                        this.laneStatus = ELaneStatus.IDLE;

                        if (ttmConfig.resultsValueDuration) {
                            setTimeout(() => {
                                this.measData = null;
                            }, ttmConfig.resultsValueDuration * 1000);
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        findLane(lane: string): void {
            for (const ip in ttmConfig.stations) {
                if (ttmConfig.stations[ip].master === lane || ttmConfig.stations[ip].name === lane) {
                    this.laneInfo.name = ttmConfig.stations[ip].name ?? lane;
                    this.laneInfo.ipAddr = ttmConfig.stations[ip].master;
                    break;
                }
            }

            this.activePage = ttmConfig.viewType;

            if( !this.laneInfo.ipAddr ) {
                this.$router.push(paths.MONITOR).catch(() => {
                    // DONE
                });
            } else {
                network.setWebsocketCallback(this.sockReceiver.bind(this));
                this.speedLimit = ttmConfig.measUnit === 'mm' ? '5 - 8 km/h' : '3 - 5 mph';
            }
        }

        nextPage (): void {
            if (this.activePage === EViewType.Basic) {
                this.activePage = EViewType.Standard;
            } else if (this.activePage === EViewType.Standard) {
                this.activePage = EViewType.Expert;
            } else {
                this.activePage = EViewType.Basic;
            }
        }

        previousPage (): void {
            if (this.activePage === EViewType.Basic) {
                this.activePage = EViewType.Expert;
            } else if (this.activePage === EViewType.Standard) {
                this.activePage = EViewType.Basic;
            } else {
                this.activePage = EViewType.Standard;
            }
        }

        get onlyIp (): string {
            const index = this.laneInfo.ipAddr.indexOf(':');
            if (index > 0) {
                return this.laneInfo.ipAddr.substring(0, index);
            } else {
                return this.laneInfo.ipAddr;
            }
        }

        @Watch('$route.params.lane')
        laneChanged (): void {
            const lane = (this.$route.params?.lane ?? '') as string;
            if (lane) {
                this.findLane(lane);
            } else {
                this.$router.push(paths.MONITOR).catch(() => {
                    // DONE
                });
            }
        }
    }
