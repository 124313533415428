































import { Component, Vue, Prop } from 'vue-property-decorator';
import AddIcon from '@/assets/svg/add.svg?inline';
import RemoveIcon from '@/assets/svg/remove.svg?inline';
import { IKeyValuePair } from '@/services/config-if';

@Component({
    components: {
        AddIcon,
        RemoveIcon
    }
})

export default class KveEditor extends Vue {
    @Prop() kvList!: IKeyValuePair [];

    addItem (): void {
        this.kvList.push({key: '', value: ''});
        this.dataChanged();
    }

    removeItem (index: number): void {
        this.kvList.splice(index, 1);
        this.dataChanged();
    }

    dataChanged (): void {
        this.$emit('dataChanged');
    }
}
