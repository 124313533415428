

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import backIcon from '@/assets/svg/back.svg?inline';
import { network } from '@/services/network';
import Spinner from '@/components/spinner.vue';
import IpInput from '@/components/ip-input.vue';
import { ESocketStatus, ISocketMessage } from '@/services/network-if';
import OnOffSwitch from '@/components/onoff-switch.vue';
import { IStation } from '@/services/config-if';

@Component({
    components: { backIcon, Spinner, IpInput, OnOffSwitch }
})

export default class ModuleConfig extends Vue {
    @Prop() station!: IStation;
    showSpinner = false;
    addresses: string [] = [];
    selectedAddress = '';
    dnsAddr = '0.0.0.0';
    gatewayAddr = '0.0.0.0';
    hasChanged = false;
    enableGatewaySwitch = false;
    enableDnsSwitch = false;

    async mounted (): Promise<void>{
        network.setWebsocketCallback(this.sockReceiver.bind(this));
        const recv = await network.apiRequest('/api/monitor/get-net-interfaces') as { interfaces: string [] };
        this.addresses = recv?.interfaces ?? [];
    }

    loadHistory (): void {
        this.showSpinner = true;
        const url = `api/monitor/history?count=1000&type=RECORDS&addr=${this.station.master}`;
        network.apiRequest(url).then(() => {
            // NOTHING TO DO
        });
    }

    targetAddress (ip: string): void {
        this.selectedAddress = ip;
        this.dnsAddr = ip;
        this.gatewayAddr = ip;
    }

    async writeConfig (): Promise<void> {
        if (this.hasChanged) {
            this.hasChanged = false;
            const urlBody = '/api/monitor/set-push-config?';
            const target = `target=${this.selectedAddress}`;
            const gateway = `&gateway=${this.enableGatewaySwitch ? this.gatewayAddr : '0.0.0.0'}`;
            const dns = `&dns=${this.enableDnsSwitch ? this.dnsAddr : '0.0.0.0'}`;
            const moduleIp = `&moduleIp=${this.station.master}`;
            await network.apiRequest(`${urlBody}${target}${gateway}${dns}${moduleIp}`);
        }
    }

    async sockReceiver (data: string): Promise<void>{
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;
            if (msg.status === ESocketStatus.OK) {
                this.showSpinner = false;
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    enableDns (): void {
        this.enableDnsSwitch = !this.enableDnsSwitch;
        if (this.enableDnsSwitch && this.selectedAddress) {
            this.hasChanged = true;
        } else {
            if (!this.selectedAddress) {
                this.hasChanged = false;
            }
        }
    }

    enableGateway (): void {
        this.enableGatewaySwitch = !this.enableGatewaySwitch;
        if (this.enableGatewaySwitch && this.selectedAddress) {
            this.hasChanged = true;
        } else {
            if (!this.selectedAddress) {
                this.hasChanged = false;
            }
        }
    }

    @Watch('selectedAddress', { immediate: false })
    @Watch('dnsAddr', { immediate: false })
    @Watch('gatewayAddr', { immediate: false })
    changed (): void {
        this.hasChanged = true;
    }
}
