import { dateTimeFormats, loadLocaleMessages } from '@/i18n';
import VueI18n from 'vue-i18n';

class I18nTTM extends VueI18n {
    constructor () {
        super({
            locale: 'en',
            fallbackLocale: 'en',
            messages: loadLocaleMessages(),
            dateTimeFormats
        });
    }

    setLocale (lang: string) {
        super.locale = lang;
    }

    getLocale () {
        return super.locale;
    }
}

export const i18nTTM = new I18nTTM();
