







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component

export default class OnOffSwitch extends Vue {
    @Prop() status!: boolean;
}
