


































import { Component, Vue, Watch } from 'vue-property-decorator';
import { locales, localesRegion } from '@/i18n';
import { ttmConfig } from '@/services/config';

interface language {
    short: string;
    name: string;
}

@Component

export default class RegionSetup extends Vue {
    languages: language [] = [];
    locales: string [] = [];
    units: string [] = ['mm', 'inch'];
    ttmConfig = ttmConfig;
    selectedRegion = '';

    mounted (): void {
        for (const ln in locales) {
            const arr = locales[ln].split('-');
            this.languages.push({ short: arr[0].trim(), name: arr[1].trim() });
        }

        for (const ln in localesRegion) {
            for (const rg of localesRegion[ln]) {
                this.locales.push(rg);
            }
        }

        this.selectedRegion = ttmConfig.locale;
    }

    changeLang (short: string): void {
        ttmConfig.setRegionItems([{ key: 'language', value: short.toLowerCase() }]);
        this.selectedRegion = localesRegion[short.toLowerCase()][0];
    }

    @Watch('selectedRegion')
    changeRegion (): void {
        ttmConfig.setRegionItems([{ key: 'locale', value: this.selectedRegion }]);
    }
}
