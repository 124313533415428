





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ttmConfig } from '@/services/config';
import statusOk from '@/assets/svg/status-ok.svg?inline';
import statusMarginal from '@/assets/svg/status-marginal.svg?inline';
import statusReplace from '@/assets/svg/status-replace.svg?inline';
import patternConcave from '@/assets/svg/pattern-concave.svg?inline';
import patternConvex from '@/assets/svg/pattern-convex.svg?inline';
import patternLinearLeft from '@/assets/svg/pattern-linear-left.svg?inline';
import patternLinearRight from '@/assets/svg/pattern-linear-right.svg?inline';
import patternPlain from '@/assets/svg/pattern-plain.svg?inline';
import TireScheme from '@/components/measview/tire-scheme.vue';
import { ETirePosition, EValueRating, IEvaluatedTireSet, IProfileScheme, WPStr } from '@/services/network-if';
import { Fn } from '@/services/fn';

interface IRegionSet {
    str: string;
    rating: EValueRating;
    lowest: boolean;
}

@Component({
    components: {
        statusOk,
        statusMarginal,
        statusReplace,
        patternConcave,
        patternConvex,
        patternLinearLeft,
        patternLinearRight,
        patternPlain,
        TireScheme
    }
})

export default class ExpertTreadValue extends Vue {
    @Prop() position!: ETirePosition;
    @Prop() posText!: string;
    @Prop() tireSet!: IEvaluatedTireSet;
    @Prop() liteSkin!: boolean;
    unitStr = '';
    ETirePosition = ETirePosition;
    EValueRating = EValueRating;
    WPStr = WPStr;
    outerSet: IRegionSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
    middleSet: IRegionSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
    innerSet: IRegionSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
    wearPattern = WPStr.ok;
    profile: IProfileScheme | null = null;

    mounted (): void {
        this.unitStr = (ttmConfig.measUnit === 'mm' ? 'mm' : 'inch/32');
    }

    resetRegionSet (): void {
        this.outerSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
        this.middleSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
        this.innerSet = { str: '', rating: EValueRating.NEUTRAL, lowest: false };
    }

    findLowest (): void {
        if (this.tireSet) {
            const values: number [] = [];

            if (!isNaN(this.tireSet.innerRegionValue.value)) {
                values.push(this.tireSet.innerRegionValue.value);
            }
            if (!isNaN(this.tireSet.middleRegionValue.value)) {
                values.push(this.tireSet.middleRegionValue.value);
            }
            if (!isNaN(this.tireSet.outerRegionValue.value)) {
                values.push(this.tireSet.outerRegionValue.value);
            }

            if (values) {
                const min = Math.min(...values);
                if (min === this.tireSet.innerRegionValue.value) {
                    this.innerSet.lowest = true;
                }
                if (min === this.tireSet.middleRegionValue.value) {
                    this.middleSet.lowest = true;
                }
                if (min === this.tireSet.outerRegionValue.value) {
                    this.outerSet.lowest = true;
                }
            }
        }
    }

    valueClass (set: IRegionSet): {std: boolean, big: boolean, stdlite: boolean, biglite: boolean} {
        return {
            std: !set.lowest && !this.liteSkin,
            big: set.lowest && !this.liteSkin,
            stdlite: !set.lowest && this.liteSkin,
            biglite: set.lowest && this.liteSkin
        };
    }

    @Watch('tireSet', { immediate: true })
    update (): void {
        if (this.tireSet) {
            this.resetRegionSet();

            if (!this.tireSet.deepGroove) {
                this.outerSet.str = Fn.formatTread(this.tireSet.outerRegionValue.value, ttmConfig.measUnit);
                this.outerSet.rating = this.tireSet.outerRegionValue.rating;
            }

            this.middleSet.str = Fn.formatTread(this.tireSet.middleRegionValue.value, ttmConfig.measUnit);
            this.middleSet.rating = this.tireSet.middleRegionValue.rating;
            if (this.tireSet.deepGroove) {
                this.middleSet.str = `> ${parseFloat(this.middleSet.str).toFixed(0)}`;
                this.middleSet.lowest = true;
            }

            if (!this.tireSet.deepGroove) {
                this.innerSet.str = Fn.formatTread(this.tireSet.innerRegionValue.value, ttmConfig.measUnit);
                this.innerSet.rating = this.tireSet.innerRegionValue.rating;
            }

            this.wearPattern = this.tireSet.wearPattern;
            this.findLowest();

            if (this.position === ETirePosition.FL || this.position === ETirePosition.RL) {
                this.profile = {
                    left: this.tireSet.outerRegionValue,
                    middle: this.tireSet.middleRegionValue,
                    right: this.tireSet.innerRegionValue
                };
            } else {
                this.profile = {
                    left: this.tireSet.innerRegionValue,
                    middle: this.tireSet.middleRegionValue,
                    right: this.tireSet.outerRegionValue
                };
            }
        }
    }
}
