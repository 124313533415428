






















































import { Component, Vue } from 'vue-property-decorator';
import { ttmConfig } from '@/services/config';
import { IPushService } from '@/services/config-if';
import TextInput from '@/components/text-input.vue';
import KveEditor from '@/components/key-value-editor.vue';
import OnOffSwitch from '@/components/onoff-switch.vue';
import RemoveIcon from '@/assets/svg/remove.svg?inline';
import ApplyIcon from '@/assets/svg/apply.svg?inline';
import AddIcon from '@/assets/svg/add.svg?inline';
import _ from 'lodash';

@Component({
    components: {
        TextInput,
        KveEditor,
        OnOffSwitch,
        RemoveIcon,
        ApplyIcon,
        AddIcon
    }
})

export default class ReceiverSetup extends Vue {
    pushServices: IPushService [] = [];
    dataChanged = false;
    showKveEditor = (-1);

    mounted(): void {
        this.pushServices = _.cloneDeep(ttmConfig.pushServices);
    }

    apply (): void {
        if (this.dataChanged) {
            this.dataChanged = false;
            ttmConfig.setPushReceiver(this.pushServices);
        }
    }

    add (): void {
        this.pushServices.push({
            processor: 'generic',
            forward: '',
            customHeaders: [],
            communicationTimeout: 30000,
            enabled: false
        });
        this.dataChanged = true;
    }

    remove (index: number): void {
        this.pushServices.splice(index, 1);
        this.dataChanged = true;
    }

    editParams (service: IPushService, index: number): void {
        if (this.showKveEditor === index) {
            this.showKveEditor = (-1);
        } else {
            this.showKveEditor = index;
        }
    }
}
