import { render, staticRenderFns } from "./page-frame.vue?vue&type=template&id=57ac4a2a&scoped=true&"
import script from "./page-frame.vue?vue&type=script&lang=ts&"
export * from "./page-frame.vue?vue&type=script&lang=ts&"
import style0 from "./page-frame.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./page-frame.vue?vue&type=style&index=1&id=57ac4a2a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ac4a2a",
  null
  
)

export default component.exports