






























































































































import { Component, Vue } from 'vue-property-decorator';
import { ttmConfig } from '@/services/config';
import UpDownInput from '@/components/updown-input.vue';
import OnOffSwitch from '@/components/onoff-switch.vue';
import WinterIcon from '@/assets/svg/winter.svg?inline';
import SummerIcon from '@/assets/svg/summer.svg?inline';
import BackwardsIcon from '@/assets/svg/opposite-backwards.svg?inline';
import ForwardsIcon from '@/assets/svg/opposite-forwards.svg?inline';

interface ILimitProps {
    minimum: number;
    recommended: number;
    changed: boolean;
}

@Component({
    components: {
        UpDownInput,
        OnOffSwitch,
        WinterIcon,
        SummerIcon,
        BackwardsIcon,
        ForwardsIcon
    }
})

export default class MeasureSetup extends Vue {
    ttmConfig = ttmConfig;
    maxVal = 0;
    step = 0;
    unit = ''
    limitsWinter: ILimitProps = { minimum: 0, recommended: 0, changed: false };
    limitsSummer: ILimitProps = { minimum: 0, recommended: 0, changed: false };
    patternLimit = 0;
    deepGroove = false;

    mounted (): void {
        if (ttmConfig.measUnit === 'mm') {
            this.maxVal = 8;
            this.step = 0.1;
            this.unit = ttmConfig.measUnit;
        } else {
            this.maxVal = 10;
            this.step = 1;
            this.unit = 'inch/32';
        }
        this.setLimits();
        this.deepGroove = ttmConfig.DeepGrooveEnabled;
    }

    setLimits (): void {
        if (ttmConfig.measUnit === 'mm') {
            this.limitsSummer.minimum = ttmConfig.SummerLimits[0];
            this.limitsSummer.recommended = ttmConfig.SummerLimits[1];
            this.limitsWinter.minimum = ttmConfig.WinterLimits[0];
            this.limitsWinter.recommended = ttmConfig.WinterLimits[1];
            this.patternLimit = ttmConfig.LinearWearPatternLimit;
        } else {
            this.limitsSummer.minimum = Math.round(ttmConfig.SummerLimits[0] * 32.0 / 25.4);
            this.limitsSummer.recommended = Math.round(ttmConfig.SummerLimits[1] * 32.0 / 25.4);
            this.limitsWinter.minimum = Math.round(ttmConfig.WinterLimits[0] * 32.0 / 25.4);
            this.limitsWinter.recommended = Math.round(ttmConfig.WinterLimits[1] * 32.0 / 25.4);
            this.patternLimit = Math.round(ttmConfig.LinearWearPatternLimit * 32.0 / 25.4);
        }
    }

    enableWinter (): void {
        ttmConfig.setWinterEnabled(true);
    }

    enableSummer (): void {
        ttmConfig.setWinterEnabled(false);
    }

    minSummerChanged (value: number): void {
        this.limitsSummer.minimum = value;
        this.limitsSummer.changed = true;
    }

    recSummerChanged (value: number): void {
        this.limitsSummer.recommended = value;
        this.limitsSummer.changed = true;
    }

    minWinterChanged (value: number): void {
        this.limitsWinter.minimum = value;
        this.limitsWinter.changed = true;
    }

    recWinterChanged (value: number): void {
        this.limitsWinter.recommended = value;
        this.limitsWinter.changed = true;
    }

    get canUpdateWinter (): boolean {
        return (this.limitsWinter.changed && this.limitsWinter.minimum < this.limitsWinter.recommended);
    }

    get canUpdateSummer () : boolean{
        return (this.limitsSummer.changed && this.limitsSummer.minimum < this.limitsSummer.recommended);
    }

    patternLimitChanged (value: number): void {
        this.patternLimit = value;
        if (ttmConfig.measUnit === 'mm') {
            ttmConfig.setLinearWearPatternLimit(value);
        } else {
            ttmConfig.setLinearWearPatternLimit(value * 25.4 / 32.0);
        }
    }

    updateSummer (): void {
        if (this.canUpdateSummer) {
            if (ttmConfig.measUnit === 'mm') {
                ttmConfig.setSummerLimits(this.limitsSummer.minimum, this.limitsSummer.recommended);
            } else {
                ttmConfig.setSummerLimits(
                    (this.limitsSummer.minimum * 25.4 / 32.0),
                    (this.limitsSummer.recommended * 25.4 / 32.0)
                );
            }
            this.limitsSummer.changed = false;
        }
    }

    updateWinter (): void {
        if (this.canUpdateWinter) {
            if (ttmConfig.measUnit === 'mm') {
                ttmConfig.setWinterLimits(this.limitsWinter.minimum, this.limitsWinter.recommended);
            } else {
                ttmConfig.setWinterLimits(
                    (this.limitsWinter.minimum * 25.4 / 32.0),
                    (this.limitsWinter.recommended * 25.4 / 32.0)
                );
            }
            this.limitsWinter.changed = false;
        }
    }

    setDirection (value: string): void {
        ttmConfig.setConsideredDirection(value);
    }

    switchDeepGroove (): void {
        this.deepGroove = !this.deepGroove;
        ttmConfig.setDeepGroove(this.deepGroove);
    }

    switchOppositeCross (status: boolean): void {
      ttmConfig.setSwapValuesOnReversing(status);
    }
}
