




















import { Component, Vue } from 'vue-property-decorator';
import { paths } from '@/router/paths';

@Component

export default class AuthWidget extends Vue {
    paths = paths;
    password = '';
    wrongPw = false;
    readonly pwHash = 3885538726;
    readonly pwSrvHash = 2626003118

    mounted (): void {
        const inp = this.$refs.input as HTMLInputElement;
        inp.focus();
    }

    monitor (): void {
        this.$router.push(paths.MONITOR).catch(() => {
            // NOTHING TO DO
        });
    }

    check (): void {
        if (this.hashString(this.password) === this.pwHash) {
            this.wrongPw = false;
            this.$emit('authSuccess', 'admin');
        } else if (this.hashString(this.password) === this.pwSrvHash) {
            this.wrongPw = false;
            this.$emit('authSuccess', 'service');
            this.wrongPw = true;
        } else {
            this.wrongPw = true;
        }
    }

    // djb2 algorithm
    hashString (str: string): number {
        let hashVal = 5381;
        for (let i = 0; i < str.length; ++i) {
            const cc = str.charCodeAt(i);
            hashVal = ((hashVal << 5) + hashVal) + cc;
        }
        return hashVal;
    }
}
