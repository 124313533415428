







































import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardTreadValue from '@/components/measview/standard-tread-value.vue';
import { ETirePosition, IEvaluatedVehicleSet } from '@/services/network-if';
import { ttmConfig } from '@/services/config';

@Component({
    components: { StandardTreadValue }
})

export default class StandardView extends Vue {
    @Prop() evalData!: IEvaluatedVehicleSet;
    ETirePosition = ETirePosition;
    liteSkin = true;

    mounted (): void {
        this.liteSkin = ttmConfig.liteSkin;
    }

    getTr(id: string): string {
        return this.$t(id) as string;
    }
}
