export enum EViewType {
    Basic,
    Standard,
    Expert
}

export interface IKeyValue {
    key: string;
    value: string | number;
}

export interface IStation {
    master: string;
    satellite: string;
    name: string;
    ident: string;
    easyTread: boolean;
    connectivityCheck: boolean;
}

export interface IKeyValuePair {
    key: string;
    value: string;
}

export interface IPushService {
    processor: string;
    forward: string;
    customHeaders: IKeyValuePair [];
    communicationTimeout: number;
    enabled: boolean;
}

export interface IAppearance {
    defaultView: string,
    liveMonitor: {
        showLane: boolean;
        hideAfterSecs: number;
    };
    crossingMonitor: {
        viewType: EViewType;
        resultsViewDuration: number;
        liteSkin: boolean;
    };
}

export interface ISettings{
    region: {
        language: string;
        locale: string;
        unit: string;
        tzoffset: string;
    };

    workshop: {
        company: string,
        address: string,
        zipCity: string,
        web: string,
        email: string,
        logo: string
    };

    Stations: { [id: string]: IStation };

    measure: {
        useWinterLimits: boolean;
        summerLimits: {
            minimum: number;
            recommended: number;
        };
        winterLimits: {
            minimum: number;
            recommended: number;
        };
        linearWearPatternLimit: number;
        deepGroove: boolean;
        consideredCrossingDirection: string;
        swapValuesOnReversing: boolean;
    };

    orderAgent: {
        autoStart: boolean;
        asaNetworkEnabled: boolean
    };

    appearance: IAppearance;
    push: IPushService [];
}
