import { render, staticRenderFns } from "./expert-tread-value.vue?vue&type=template&id=2ac05f4f&scoped=true&"
import script from "./expert-tread-value.vue?vue&type=script&lang=ts&"
export * from "./expert-tread-value.vue?vue&type=script&lang=ts&"
import style0 from "./expert-tread-value.vue?vue&type=style&index=0&id=2ac05f4f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac05f4f",
  null
  
)

export default component.exports