import axios from 'axios';
import { ESocketStatus, ISocketMessage, IVersion } from '@/services/network-if';

/* eslint-disable @typescript-eslint/no-explicit-any*/

interface IMessageStorage {
    datetime: string;
    message: string;
}

class Network {
    ws: WebSocket | null = null;
    readonly checkTimeout = 1000;
    wsCallback: { (data: string): void; } [] = [];
    firstInstance = false;
    connectionLost = false;
    lastProcessedMessage = new Date().toISOString();

    startWebsocket () {
        try {
            if (window.location.protocol.toUpperCase().indexOf('HTTPS') !== (-1)) {
                this.ws = new WebSocket(`wss://${window.location.host}/api`);
            } else {
                this.ws = new WebSocket(`ws://${window.location.host}/api`);
            }
        } catch (e) {
            this.ws = null;
        }

        if (this.ws) {
            this.ws.onmessage = (msg) => {
                if (msg.data === 'FIRST_INSTANCE') {
                    this.firstInstance = true;
                } else if (msg.data !== 'NOT_FIRST_INSTANCE') {
                    this.wsCallback.forEach(cb => cb(msg.data));
                }
            };

            this.ws.onclose = async () => {
                this.ws = null;
            };

            this.ws.onopen = () => {
                this.sendConnectionStatus(ESocketStatus.CONNECTION_OPENED);
                if (this.ws) {
                    this.ws.send('IS_FIRST_INSTANCE');
                }
            };

            this.ws.onerror = async () => {
                if (this.ws) {
                    this.ws.onclose = () => {
                        // NOTHING TO DO
                    };
                    this.ws.close();
                    this.ws = null;
                }
            };
        }

        setTimeout(this.checkConnection.bind(this), this.checkTimeout);
    }

    async checkConnection () {
        if (this.ws && this.ws.readyState === 1) {
            setTimeout(this.checkConnection.bind(this), this.checkTimeout);
        } else {
            await this.wsMessage();
            setTimeout(() => { this.startWebsocket(); }, this.checkTimeout);
        }
    }

    setWebsocketCallback (wsCallback: { (data: string):void; }) {
        this.wsCallback.push(wsCallback);
    }

    get isFirstInstance () {
        return this.firstInstance;
    }

    async apiRequest (url: string) {
        try {
            const client = axios.create();
            client.defaults.timeout = 5000;
            const result = await client.get(url);
            return result.data;
        } catch (e) {
            return null;
        }
    }

    async apiPost (url: string, data: any) {
        try {
            const client = axios.create();
            client.defaults.timeout = 5000;
            await client.post(url, data, { headers: { 'Content-Type': 'application/json' } });
        } catch (e) {
            // FAILED
        }
    }

    async apiUpload (url: string, formData: FormData) {
        try {
            const client = axios.create();
            client.defaults.timeout = 600000;
            await client.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        } catch (e) {
            // FAILED
        }
    }

    async proxyVersion () {
        const url = 'api/about';
        const result = await this.apiRequest(url);

        if (result) {
            return result as unknown as IVersion;
        } else {
            return null;
        }
    }

    async wsMessage () {
        const url = 'api/wsmessage';
        const result = await this.apiRequest(url) as unknown as IMessageStorage [];

        if (result !== null) {
            if (this.connectionLost) {
                this.connectionLost = false;
                this.sendConnectionStatus(ESocketStatus.CONNECTION_OPENED);
            }

            for(const item of result) {
                if( new Date(item.datetime) > new Date(this.lastProcessedMessage)) {
                    this.lastProcessedMessage = item.datetime;
                    this.wsCallback.forEach(cb => cb(item.message));
                }
            }
        } else {
            this.sendConnectionStatus(ESocketStatus.CONNECTION_LOST);
            this.connectionLost = true;
        }
    }

    sendConnectionStatus (status: ESocketStatus) {
        const response: ISocketMessage = {
            status,
            data: { }
        };
        this.wsCallback.forEach(cb => cb(JSON.stringify(response)));
    }
}

const network = new Network();
export { network };
