






























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageFrame from '@/components/page-frame.vue';
import ReportPreview from '@/components/report-preview.vue';
import Spinner from '@/components/spinner.vue';
import ForewardsCar from '@/assets/svg/statuscar-forwards.svg?inline';
import OppositeForewardsCar from '@/assets/svg/statuscar-opposite-forwards.svg?inline';
import BackwardsCar from '@/assets/svg/statuscar-backwards.svg?inline';
import downloadIcon from '@/assets/svg/download.svg?inline';
import PlateSearch from '@/assets/svg/plate-search.svg?inline';
import PlateSearchClear from '@/assets/svg/plate-search-clear.svg?inline';
import ModuleConn from '@/assets/svg/module-connection.svg?inline';
import GoPrev from '@/assets/svg/go-prev.svg?inline';
import GoNext from '@/assets/svg/go-next.svg?inline';
import GoFirst from '@/assets/svg/go-first.svg?inline';
import GoLast from '@/assets/svg/go-last.svg?inline';
import CheckAlignment from '@/assets/svg/check-alignment.svg?inline';
import CheckPressure from '@/assets/svg/check-pressure.svg?inline';
import HiddenIcon from '@/assets/svg/hidden.svg?inline';
import { network } from '@/services/network';
import {
    ESocketStatus,
    EValueRating,
    IEvaluatedTireSet,
    IEvaluatedVehicleSet,
    ISocketMessage,
    WPStr
} from '@/services/network-if';
import { ttmConfig } from '@/services/config';
import { EHint, ELaneStatus, ILaneData, IMonitorRecord, IMonitorTread } from '@/services/monitor-if';
import _ from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any*/

@Component({
    components: {
        PageFrame,
        Spinner,
        ForewardsCar,
        BackwardsCar,
        downloadIcon,
        PlateSearch,
        PlateSearchClear,
        ModuleConn,
        ReportPreview,
        OppositeForewardsCar,
        GoPrev,
        GoNext,
        GoFirst,
        GoLast,
        CheckAlignment,
        CheckPressure,
        HiddenIcon
    }
})

export default class MonitorPage extends Vue {
    records: IMonitorRecord [] = [];
    currentPage = 1;
    pageCount = 1;
    ttmConfig = ttmConfig;
    EHint = EHint;
    showReport = false;
    reportTimestamp = '';
    reportPlate = '';
    errorMessage = '';
    plateFilter = '';
    showPlateSearch = false;
    showLostModules = false;
    alwaysShowPlates = false;
    showDbUpdate = false;
    lostModules: string [] = [];
    readonly pageSize = 10;

    async mounted (): Promise<void> {
        network.setWebsocketCallback(this.sockReceiver.bind(this));
        await this.loadCrossings();
    }

    async loadCrossings (): Promise<void> {
        await this.calculatePages();

        const url =
            `api/monitor/crossings?pageno=${this.currentPage}&viewport=${this.pageSize}&filter=${this.plateFilter}`;
        const recv = await network.apiRequest(url) as any;
        const crossings = recv?.crossings ?? null;

        if (crossings) {
            this.records = [];
            for (const rc of crossings) {
                this.records.push({
                    timestamp: rc.timestamp,
                    measplace: rc.measplace,
                    customer: rc.customer,
                    vehicle: rc.vehicle,
                    vehicleRating: this.vehicleStatus(rc.evalData),
                    crossingDirection: rc.evalData.crossingDirection,
                    frontLeft: {
                        value: rc.evalData.frontLeft.minimumValue.value,
                        rating: rc.evalData.frontLeft.minimumValue.rating,
                        deepGroove: rc.evalData.frontLeft.deepGroove
                    },
                    frontRight: {
                        value: rc.evalData.frontRight.minimumValue.value,
                        rating: rc.evalData.frontRight.minimumValue.rating,
                        deepGroove: rc.evalData.frontRight.deepGroove
                    },
                    rearLeft: {
                        value: rc.evalData.rearLeft.minimumValue.value,
                        rating: rc.evalData.rearLeft.minimumValue.rating,
                        deepGroove: rc.evalData.rearLeft.deepGroove
                    },
                    rearRight: {
                        value: rc.evalData.rearRight.minimumValue.value,
                        rating: rc.evalData.rearRight.minimumValue.rating,
                        deepGroove: rc.evalData.rearRight.deepGroove
                    },
                    hint: this.setHints(rc.evalData)
                });
            }
        }
    }

    fmtValue (meas: IMonitorTread): string {
        const value = meas?.value ?? NaN;
        if (!isNaN(value)) {
            if (meas.deepGroove) {
                return (ttmConfig.measUnit === 'mm' ? '> 8' : '> 10');
            } else {
                return value.toFixed(ttmConfig.measUnit === 'mm' ? 1 : 0);
            }
        } else {
            return '-';
        }
    }

    fmtDate (dateTime: string): string {
        if (dateTime) {
            const formatter = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' };
            const df = new Intl.DateTimeFormat(ttmConfig.locale, formatter);
            return df.format(new Date(dateTime));
        }
        return '';
    }

    fmtTime (dateTime: string): string {
        if (dateTime) {
            const formatter = { hour: 'numeric', minute: 'numeric' };
            const df = new Intl.DateTimeFormat(ttmConfig.locale, formatter);
            return df.format(new Date(dateTime));
        }
        return '';
    }

    fmtTimeSec (dateTime: string): string {
        if (dateTime) {
            const formatter = { second: 'numeric' };
            const df = new Intl.DateTimeFormat(ttmConfig.locale, formatter);
            return df.format(new Date(dateTime)).padStart(2, '0');
        }
        return '';
    }

    rating (rating: EValueRating): { red: boolean, green: boolean, yellow: boolean, black: boolean } {
        return {
            red: rating === EValueRating.REPLACE,
            green: rating === EValueRating.GOOD,
            yellow: rating === EValueRating.MARGINAL,
            black: rating === EValueRating.NEUTRAL
        };
    }

    iconRating (rating: EValueRating):
        { 'monitor-table-red': boolean, 'monitor-table-green': boolean, 'monitor-table-yellow': boolean, 'monitor-table-rowsvg': boolean } {
        return {
            'monitor-table-red': rating === EValueRating.REPLACE,
            'monitor-table-green': rating === EValueRating.GOOD,
            'monitor-table-yellow': rating === EValueRating.MARGINAL,
            'monitor-table-rowsvg': rating === EValueRating.NEUTRAL
        };
    }

    get valueWidthClass (): {w20: boolean, w26: boolean} {
        return {
            w20: ttmConfig.showMonitorLane,
            w26: !ttmConfig.showMonitorLane
        }
    }

    vehicleVisible (timestamp: string): boolean {
        if (!this.alwaysShowPlates) {
            const diff = Math.abs(new Date().getTime() - new Date(timestamp).getTime()) / 1000;
            if (ttmConfig.vehicleEntrysHideDelay > 0 && diff > (ttmConfig.vehicleEntrysHideDelay * 60)) {
                return false;
            }
        }
        return true;
    }

    switchPlatesVisibility (): void {
        if (ttmConfig.vehicleEntrysHideDelay > 0) {
            this.alwaysShowPlates = !this.alwaysShowPlates;
        }
    }

    setHints (data: IEvaluatedVehicleSet): EHint {
        let waCheck = false;
        let pressureCheck = false;
        const tires = [data.frontLeft, data.frontRight, data.rearLeft, data.rearRight];

        for (const tire of tires) {
            if (tire.wearPattern === WPStr.linearWearLeft ||
                tire.wearPattern === WPStr.linearWearRight) {
                waCheck = true;
            } else if (tire.wearPattern === WPStr.parabolicWearCentered ||
                tire.wearPattern === WPStr.parabolicWearOutside) {
                pressureCheck = true;
            }
        }

        if (waCheck && pressureCheck) {
            return EHint.All;
        } else if( waCheck) {
            return EHint.WheelAlignment;
        } else if (pressureCheck) {
            return EHint.TirePressure;
        }
        return EHint.None;
    }

    async sockReceiver (data: string): Promise<void> {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;

            switch (msg.status) {
                case ESocketStatus.CONNECTION_OPENED:
                    await this.loadCrossings();
                    this.errorMessage = '';
                    break;
                case ESocketStatus.CONNECTION_LOST:
                    this.errorMessage = this.$t('monitor.connectionProxyLost') as string;
                    break;
                case ESocketStatus.CONNECTION_STATUS_MODULE: {
                    this.lostModules = [];
                    const metaData = msg.data as ILaneData;

                    if (metaData.status === ELaneStatus.DISCONNECTED) {
                        const name = ttmConfig.stationName(metaData.ipAddr);
                        this.lostModules.push(name ? name : metaData.ipAddr);
                    } else if (metaData.status === ELaneStatus.CONNECTED) {
                        if( this.lostModules) {
                            const name = ttmConfig.stationName(metaData.ipAddr);
                            const st = _.cloneDeep(this.lostModules);
                            this.lostModules = [];

                            for (const s of st) {
                                if( s !== name && s !== metaData.ipAddr) {
                                    this.lostModules.push(s);
                                }
                            }
                        }
                    }

                    this.errorMessage = '';
                    break;
                }
                case ESocketStatus.DATABASE_UPDATE:
                    this.showDbUpdate= true;
                    this.errorMessage = '';
                    break;
                case ESocketStatus.CONFIGURATION_CHANGED:
                    await this.loadCrossings();
                    break;
                default:
                    this.showDbUpdate= false;
                    if (msg.data.msg === 'NEW_CROSSING') {
                        await this.loadCrossings();
                    }
                    break;
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    get nextActive (): boolean {
        return (this.currentPage < this.pageCount);
    }

    get previousActive (): boolean {
        return (this.currentPage > 1);
    }

    async pageUp () : Promise<void> {
        if (this.nextActive) {
            this.currentPage++;
            await this.loadCrossings();
        }
    }

    async pageDown (): Promise<void> {
        if (this.previousActive) {
            this.currentPage--;
            await this.loadCrossings();
        }
    }

    async pageFirst (): Promise<void>{
        this.currentPage = 1;
        await this.loadCrossings();
    }

    async pageLast (): Promise<void> {
        this.currentPage = this.pageCount;
        await this.loadCrossings();
    }

    openReport (timestamp: string, plate: string): void {
        this.reportTimestamp = timestamp;
        this.showReport = true;
        this.reportPlate = plate;
    }

    vehicleStatus (item: IEvaluatedVehicleSet): EValueRating {
        let vehicleRating = EValueRating.NEUTRAL;
        const tires: IEvaluatedTireSet [] = [item.frontLeft, item.frontRight, item.rearLeft, item.rearRight];

        for (const it of tires) {
            if (it.minimumValue.rating > vehicleRating) {
                vehicleRating = it.minimumValue.rating;
            }
        }

        return vehicleRating;
    }

    async calculatePages (): Promise<void> {
        const recv = await network.apiRequest(`api/monitor/record-count?filter=${this.plateFilter}`) as any;
        const records = recv?.records ?? 0;

        if (records < this.pageSize) {
            this.pageCount = 1;
        } else {
            this.pageCount = Math.ceil(records / this.pageSize);
        }
    }

    onPlateSearch (): void {
        this.showPlateSearch = !this.showPlateSearch;

        if (!this.showPlateSearch) {
            this.plateFilter = '';
        } else {
            setTimeout(() => {
                const inp = this.$refs.plateinp as HTMLInputElement;
                if (inp) {
                    inp.focus();
                }
            }, 500);
        }
    }

    @Watch('plateFilter', { immediate: false })
    filterView (): void {
        this.currentPage = 1;
        this.loadCrossings().then(() => {
            // NOTHING TO DO
        });
    }
}
