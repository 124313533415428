/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface IVersion {
    version: string;
    platform: string;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export enum ESocketStatus {
    OK,
    CONNECTION_OPENED,
    CONNECTION_LOST,
    CONNECTION_STATUS_MODULE,
    CONFIGURATION_CHANGED,
    SETUP_CANCELLED,
    ORDERAGENT_RESULT,
    DATABASE_UPDATE
}

export interface ISocketMessage {
    status: ESocketStatus;
    data: any;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export enum ETirePosition {
    FL,
    FR,
    RL,
    RR
}

export enum EValueRating {
    NEUTRAL,
    GOOD,
    MARGINAL,
    REPLACE
}

export const WPStr = {
    ok: 'ok',
    linearWearLeft: 'linearWearLeft',
    linearWearRight: 'linearWearRight',
    parabolicWearOutside: 'parabolicWearOutside',
    parabolicWearCentered: 'parabolicWearCentered'
};

export interface IEvaluatedTread {
    value: number;
    rating: EValueRating;
}

export interface IEvaluatedTireSet {
    globalValue: IEvaluatedTread;
    minimumValue: IEvaluatedTread;
    innerRegionValue: IEvaluatedTread;
    middleRegionValue: IEvaluatedTread;
    outerRegionValue: IEvaluatedTread;
    tireIsValid: boolean;
    deepGroove: boolean;
    wearPattern: string;
    wearPatternValue: number;
    deepGrooveValue: number;
}

export interface IEvaluatedConfiguration {
    replaceLimit: number;
    marginalLimit: number;
    unit: string;
    useWinterLimits: boolean;
}

export interface IEvaluatedLicencePlate {
    plate: string;
    plateConfidence: number;
    countryId: string;
    countryConfidence: number;
}

export interface IEvaluatedVehicleSet {
    frontLeft: IEvaluatedTireSet;
    frontRight: IEvaluatedTireSet;
    rearLeft: IEvaluatedTireSet;
    rearRight: IEvaluatedTireSet;
    vehicleIsValid: boolean;
    crossingError: string;
    softwareVersion: string;
    interfaceVersion: string;
    dateTime: string;
    crossingDirection: string;
    configuration: IEvaluatedConfiguration;
    workshop: string [];
    productName: string;
    licencePlate: IEvaluatedLicencePlate;
    vehicleRating: EValueRating;
}

export interface IProfileScheme{
    left: IEvaluatedTread;
    middle: IEvaluatedTread;
    right: IEvaluatedTread;
}
