

















































import { Component, Vue } from 'vue-property-decorator';
import KeyLock from '@/assets/svg/key-lock.svg?inline';
import backIcon from '@/assets/svg/back.svg?inline';
import OpenSubmenu from '@/assets/svg/open-submenu.svg?inline';
import { paths } from '@/router/paths';
import { ttmConfig } from '@/services/config';
import { IStation } from '@/services/config-if';

@Component({
    components: { KeyLock, backIcon, OpenSubmenu }
})

export default class MenuPopup extends Vue {
    paths = paths;
    showCrossingSubmenu = false;
    enableCrossingSubmenu = false;
    easyTreadStations: {[p: string]: IStation} = {};

    mounted (): void {
        for (const ip in ttmConfig.stations) {
            if (ttmConfig.stations[ip].easyTread) {
                this.easyTreadStations[ip] = ttmConfig.stations[ip];
                this.enableCrossingSubmenu = true;
            }
        }
    }

    monitor (): void {
        this.showCrossingSubmenu = false;
        this.$emit('leave');
        this.$router.push(paths.MONITOR).catch(() => {
            // DONE
        });
    }

    setup (): void {
        this.showCrossingSubmenu = false;
        this.$emit('leave');
        this.$router.push(paths.SETTINGS).catch(() => {
            // DONE
        });
    }

    statistics (): void {
        this.showCrossingSubmenu = false;
        this.$emit('leave');
        this.$router.push(paths.STATISTICS).catch(() => {
            // DONE
        });
    }

    crossingMenu (): void {
        if (this.enableCrossingSubmenu) {
            this.showCrossingSubmenu = !this.showCrossingSubmenu;
        }
    }

    crossing (ip: string): void {
        this.showCrossingSubmenu = false;
        this.$emit('leave');
        this.$router.push({ name: 'crossing', params: { lane: ip }}).catch(() => {
            // DONE
        });
    }

    help (): void {
        this.showCrossingSubmenu = false;
        this.$emit('leave');
        this.$router.push(paths.HELP).catch(() => {
            // DONE
        });
    }
}
