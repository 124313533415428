














import { Component, Vue } from 'vue-property-decorator';
import HorizontalTabs from '@/components/horizontal-tabs.vue';
import StationSetup from '@/components/setup/station.vue';
import ReceiverSetup from '@/components/setup/receiver.vue';
import { IStation } from '@/services/config-if';

@Component({
    components: { HorizontalTabs, StationSetup, ReceiverSetup }
})

export default class ConnectedDevices extends Vue {
    currentTab = 0;

    onTabChanged (index: number): void {
        this.currentTab = index;
    }

    showConfig (station: IStation): void {
        this.$emit('showConfig', station);
    }
}
