


























import { Component, Vue, Prop } from 'vue-property-decorator';
import bblogo from '@/assets/svg/beissbarth.svg?inline';
import menubtn from '@/assets/svg/menu.svg?inline';
import { paths } from '@/router/paths';
import { network } from '@/services/network';
import { IVersion } from '@/services/network-if';
import MenuPopup from '@/components/menu-popup.vue';
import { ttmConfig } from '@/services/config';
import { Fn } from '@/services/fn';

@Component({
    components: { bblogo, menubtn, MenuPopup }
})

export default class PageFrame extends Vue {
    @Prop({required: false, default: false}) noPadding!: boolean;
    dateTimeValue = '';
    paths = paths;
    version: IVersion = { version: 'Easy Tread Proxy', platform: '' };
    showMenu = false;

    async mounted (): Promise<void> {
        await this.proxyVersion();
        const data = await network.proxyVersion();
        if (data) {
            this.version = data;
        }
        setInterval(this.currentDateTime.bind(this), 500);
    }

    async proxyVersion (): Promise<void> {
        const data = await network.proxyVersion();
        if (data) {
            this.version = data;
        }
    }

    currentDateTime (): void {
        const formatter = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        const df = new Intl.DateTimeFormat(ttmConfig.locale, formatter);
        this.dateTimeValue = `${df.format(new Date())} ${Fn.tzOffset}`;
    }
}
