export class Fn {
    public static get tzOffset (): string {
        const dt = new Date();
        const tzoffset = -(dt.getTimezoneOffset());
        const minutes = (tzoffset % 60);
        const hours = Math.floor(tzoffset / 60);
        return `${hours >= 0 ? '+' : '-'}${Fn.numberFormat(Math.abs(hours), 2)}${Fn.numberFormat(Math.abs(minutes), 2)}`;
    }

    public static numberFormat (value: number, digits: number): string {
        const str = value.toString();
        return str.padStart(digits, '0');
    }

    public static formatTread (value: number, unit: string): string {
        if (value && !isNaN(value)) {
            return (unit === 'mm' ? value.toFixed(1) : value.toFixed(0));
        } else {
            return '---';
        }
    }
}
