import { network } from '@/services/network';
import _ from 'lodash';
import { i18nTTM } from '@/services/i18n-ttm';
import { ESocketStatus, ISocketMessage } from '@/services/network-if';
import { EViewType, IAppearance, IKeyValue, IPushService, ISettings } from '@/services/config-if';
import Vue from 'vue';
import { Mutex } from 'async-mutex';

class Configuration {
    private settings: ISettings | null = null;
    private cfgCallback: { (): void; } [] = [];
    private loadMutex = new Mutex();

    constructor () {
        this.load().then(() => {
            // DONE
        });
        network.setWebsocketCallback(this.sockReceiver.bind(this));
    }

    setConfigCallback (cfgCallback: { ():void; }) {
        this.cfgCallback.push(cfgCallback);
    }

    async load () {
        const release = await this.loadMutex.acquire();
        const recv = await network.apiRequest('/api/monitor/get-config') as { configuration: ISettings };
        const config = recv?.configuration ?? null;

        if (config) {
            this.settings = _.cloneDeep(config);
            i18nTTM.setLocale(this.language);
        }

        release();
    }

    loadAppearance () {
        if (Vue.$cookies.isKey('appearance')) {
            const data  = Vue.$cookies.get('appearance');
            return data as IAppearance;
        }
        return this.settings?.appearance;
    }

    write () {
        if (this.settings) {
            network.apiPost('/api/monitor/set-config', this.settings).then(() => {
                // NOTHING TO DO
            });
        }
    }

    sockReceiver (data: string) {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;
            if (msg.status === ESocketStatus.CONFIGURATION_CHANGED) {
                this.load().then(() => {
                    this.cfgCallback.forEach(cb => cb());
                });
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    // GETTER

    get language () {
        return this.settings?.region.language ?? 'en';
    }

    get measUnit () {
        return this.settings?.region.unit ?? 'mm';
    }

    get locale () {
        return this.settings?.region.locale ?? 'en-US';
    }

    get company () {
        return this.settings?.workshop.company ?? '';
    }

    get address () {
        return this.settings?.workshop.address ?? '';
    }

    get city () {
        return this.settings?.workshop.zipCity ?? '';
    }

    get web () {
        return this.settings?.workshop.web ?? '';
    }

    get email () {
        return this.settings?.workshop.email ?? '';
    }

    get logo () {
        return this.settings?.workshop.logo ?? '';
    }

    get consideredCrossingDirection () {
        return this.settings?.measure.consideredCrossingDirection ?? 'both';
    }

    get stations () {
        return this.settings?.Stations ?? null;
    }

    get DeepGrooveEnabled () {
        return this.settings?.measure.deepGroove ?? true;
    }

    get SwapValuesOnReversing () {
        return this.settings?.measure.swapValuesOnReversing ?? false;
    }

    get WinterLimitsEnabled () {
        return this.settings?.measure.useWinterLimits ?? false;
    }

    get WinterLimits () {
        if (this.settings) {
            return [
                this.settings.measure.winterLimits.minimum,
                this.settings.measure.winterLimits.recommended
            ];
        } else {
            return [3, 4];
        }
    }

    get SummerLimits () {
        if (this.settings) {
            return [
                this.settings.measure.summerLimits.minimum,
                this.settings.measure.summerLimits.recommended
            ];
        } else {
            return [2, 3];
        }
    }

    get LinearWearPatternLimit () {
        return this.settings?.measure.linearWearPatternLimit ?? 0.1;
    }

    get OrderAgentAutoStart () {
        return this.settings?.orderAgent.autoStart ?? false;
    }

    get AsaNetworkEnabled () {
        return this.settings?.orderAgent.asaNetworkEnabled ?? false;
    }

    get pushServices() {
        return this.settings?.push ?? [];
    }

    stationName (ipAddr: string) {
        if (this.stations) {
            for (const ip in this.stations) {
                if (ip === ipAddr || this.stations[ip].master === ipAddr) {
                    return this.stations[ip].name;
                }
            }
        }
        return '';
    }

    get viewType () {
        const appearance = this.loadAppearance();
        return appearance?.crossingMonitor.viewType ?? EViewType.Expert;
    }

    get resultsValueDuration () {
        const appearance = this.loadAppearance();
        return appearance?.crossingMonitor.resultsViewDuration ?? 15;
    }

    get showMonitorLane () {
        const appearance = this.loadAppearance();
        return appearance?.liveMonitor.showLane ?? true;
    }

    get vehicleEntrysHideDelay () {
        const appearance = this.loadAppearance();
        return appearance?.liveMonitor.hideAfterSecs ?? 0;
    }

    get liteSkin () {
        const appearance = this.loadAppearance();
        return appearance?.crossingMonitor.liteSkin ?? false;
    }

    // SETTER

    setRegionItems (data: IKeyValue []) {
        if (this.settings) {
            for (const item of data) {
                if (item.key === 'language') {
                    this.settings.region.language = item.value as string;
                    i18nTTM.setLocale(this.language);
                } else if (item.key === 'locale') {
                    this.settings.region.locale = item.value as string;
                } else if (item.key === 'unit') {
                    this.settings.region.unit = item.value as string;
                }
            }
            this.write();
        }
    }

    public setWorkshopItems (data: IKeyValue []) {
        if (this.settings) {
            for (const item of data) {
                if (item.key === 'company') {
                    this.settings.workshop.company = item.value as string;
                } else if (item.key === 'address') {
                    this.settings.workshop.address = item.value as string;
                } else if (item.key === 'city') {
                    this.settings.workshop.zipCity = item.value as string;
                } else if (item.key === 'web') {
                    this.settings.workshop.web = item.value as string;
                } else if (item.key === 'email') {
                    this.settings.workshop.email = item.value as string;
                }
            }
            this.write();
        }
    }

    public setWinterEnabled (value: boolean) {
        if (this.settings) {
            this.settings.measure.useWinterLimits = value;
            this.write();
        }
    }

    public setLinearWearPatternLimit (value: number) {
        if (this.settings) {
            this.settings.measure.linearWearPatternLimit = value;
            this.write();
        }
    }

    public setSummerLimits (min: number, rec: number) {
        if (this.settings) {
            this.settings.measure.summerLimits.minimum = min;
            this.settings.measure.summerLimits.recommended = rec;
            this.write();
        }
    }

    public setWinterLimits (min: number, rec: number) {
        if (this.settings) {
            this.settings.measure.winterLimits.minimum = min;
            this.settings.measure.winterLimits.recommended = rec;
            this.write();
        }
    }

    public setConsideredDirection (value: string) {
        if (this.settings) {
            this.settings.measure.consideredCrossingDirection = value;
            this.write();
        }
    }

    public setDeepGroove (value: boolean) {
        if (this.settings) {
            this.settings.measure.deepGroove = value;
            this.write();
        }
    }

    public setSwapValuesOnReversing (value: boolean) {
        if (this.settings) {
            this.settings.measure.swapValuesOnReversing = value;
            this.write();
        }
    }

    public setOrderAgentAutoStart (value: boolean) {
        if (this.settings) {
            this.settings.orderAgent.autoStart = value;
            this.write();
        }
    }

    public setAsaNetworkEnabled (value: boolean) {
        if (this.settings) {
            this.settings.orderAgent.asaNetworkEnabled = value;
            this.write();
        }
    }

    public setPushReceiver (value: IPushService []) {
        if (this.settings) {
            this.settings.push = value;
            this.write();
        }
    }

    public setViewType (value: EViewType, setAsDefault: boolean) {
        const appearance = this.loadAppearance();

        if (appearance) {
            appearance.crossingMonitor.viewType = value;
            Vue.$cookies.set('appearance', appearance);
        }

        if (setAsDefault && this.settings) {
            this.settings.appearance.crossingMonitor.viewType = value;
            this.write();
        }
    }

    public setResultsViewDuration (value: number, setAsDefault: boolean) {
        const appearance = this.loadAppearance();

        if (appearance) {
            appearance.crossingMonitor.resultsViewDuration = value;
            Vue.$cookies.set('appearance', appearance);
        }

        if (setAsDefault && this.settings) {
            this.settings.appearance.crossingMonitor.resultsViewDuration = value;
            this.write();
        }
    }

    public setShowMonitorLane (value: boolean, setAsDefault: boolean) {
        const appearance = this.loadAppearance();

        if (appearance) {
            appearance.liveMonitor.showLane = value;
            Vue.$cookies.set('appearance', appearance);
        }

        if (setAsDefault && this.settings) {
            this.settings.appearance.liveMonitor.showLane = value;
            this.write();
        }
    }

    public setVehicleEntrysHideDelay (value: number, setAsDefault: boolean) {
        const appearance = this.loadAppearance();

        if (appearance) {
            appearance.liveMonitor.hideAfterSecs = value;
            Vue.$cookies.set('appearance', appearance);
        }

        if (setAsDefault && this.settings) {
            this.settings.appearance.liveMonitor.hideAfterSecs = value;
            this.write();
        }
    }

    public setLiteSkin (value: boolean, setAsDefault: boolean) {
        const appearance = this.loadAppearance();

        if (appearance) {
            appearance.crossingMonitor.liteSkin = value;
            Vue.$cookies.set('appearance', appearance);
        }

        if (setAsDefault && this.settings) {
            this.settings.appearance.crossingMonitor.liteSkin = value;
            this.write();
        }
    }
}

const ttmConfig = new Configuration();
export { ttmConfig };
