










































































import { Component, Vue } from 'vue-property-decorator';
import PageFrame from '@/components/page-frame.vue';
import RegionSetup from '@/components/setup/region.vue';
import WorkshopSetup from '@/components/setup/workshop.vue';
import StationSetup from '@/components/setup/station.vue';
import ConnectedDevices from '@/components/setup/connected-devices.vue';
import MeasureSetup from '@/components/setup/measure.vue';
import ModuleConfig from '@/components/module-configuration.vue';
import AuthWidget from '@/components/auth.vue';
import SoftwareUpdate from '@/components/setup/update.vue';
import InterfacesSetup from '@/components/setup/interfaces.vue';
import ExpertEdit from '@/components/setup/expert-edit.vue';
import AppearanceSetup from '@/components/setup/appearance.vue';
import { network } from '@/services/network';
import { ESocketStatus, ISocketMessage } from '@/services/network-if';
import { IStation } from '@/services/config-if';

enum ESubPage {
    REGION,
    WORKSHOP,
    LANE,
    MEASURE,
    INTERFACES,
    SERVICE,
    EXPERT,
    APPEARANCE
}

@Component({
    components: {
        PageFrame,
        RegionSetup,
        WorkshopSetup,
        StationSetup,
        ModuleConfig,
        MeasureSetup,
        AuthWidget,
        SoftwareUpdate,
        InterfacesSetup,
        ExpertEdit,
        AppearanceSetup,
        ConnectedDevices
    }
})

export default class SettingsPage extends Vue {
    ESubPage = ESubPage
    activePage = ESubPage.APPEARANCE;
    showConfig = false;
    moduleAddr = '';
    moduleName = '';
    errorMessage = '';
    authSuccessful = false;
    authService = false;
    station: IStation = {master: '', satellite: '', name: '', ident: '', easyTread: false, connectivityCheck: false};

    mounted (): void {
        network.setWebsocketCallback(this.sockReceiver.bind(this));
    }

    async sockReceiver (data: string): Promise<void> {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage;
            switch (msg.status) {
                case ESocketStatus.CONNECTION_OPENED:
                    this.errorMessage = '';
                    break;
                case ESocketStatus.CONNECTION_LOST:
                    if (this.activePage !== 6) {
                        this.errorMessage = this.$t('monitor.connectionProxyLost') as string;
                    }
                    break;
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    onShowConfig (station: IStation): void {
        this.station = station;
        this.showConfig = true;
    }

    authDone (authType: string): void {
        this.authSuccessful = true;
        this.authService = (authType === 'service');
    }
}
