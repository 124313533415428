


































































import { Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/text-input.vue';
import TextLink from '@/components/text-link.vue';
import Spinner from '@/components/spinner.vue';
import IpInput from '@/components/ip-input.vue';
import { ttmConfig } from '@/services/config';
import { IStation } from '@/services/config-if';
import { network } from '@/services/network';
import RemoveIcon from '@/assets/svg/remove.svg?inline';
import SettingsIcon from '@/assets/svg/settings.svg?inline';
import ApplyIcon from '@/assets/svg/apply.svg?inline';

interface IStationEx {
    station: IStation;
    canApply: boolean;
}

@Component({
    components: {
        TextInput,
        TextLink,
        RemoveIcon,
        SettingsIcon,
        ApplyIcon,
        Spinner,
        IpInput
    }
})

export default class StationSetup extends Vue {
    addresses: string [] = [];
    selectedAddress = '';
    stations: { [id: string]: IStationEx } = {};
    showSpinner = false;

    mounted (): void {
        network.apiRequest('/api/monitor/get-net-interfaces').then((recv) => {
            const result = recv as { interfaces: string [] };
            this.addresses = result?.interfaces ?? [];
        });

        this.updateConfig();
        ttmConfig.setConfigCallback(this.configChanged.bind(this));
    }

    configChanged (): void {
        this.updateConfig();
        this.showSpinner = false;
    }

    updateConfig (): void {
        this.stations = {};
        for (const ip in ttmConfig.stations) {
            this.stations[ip] = {
                station: {
                    master: ttmConfig.stations[ip].master,
                    satellite: ttmConfig.stations[ip].satellite,
                    name: ttmConfig.stations[ip].name,
                    ident: ttmConfig.stations[ip].ident,
                    easyTread: ttmConfig.stations[ip].easyTread,
                    connectivityCheck: ttmConfig.stations[ip].connectivityCheck
                },
                canApply: false
            };
        }
    }

    configClicked (item: IStation): void {
        if (item.easyTread) {
            this.$emit('showConfig', item);
        }
    }

    async searchMp (): Promise<void> {
        if (this.selectedAddress) {
            this.showSpinner = true;
            const url = `/api/monitor/search-mp?addr=${this.selectedAddress}`;
            await network.apiRequest(url);
        }
    }

    async removeStation (addr: string): Promise<void> {
        const url = `/api/monitor/remove-mp?addr=${addr}`;
        await network.apiRequest(url);
    }

    addIp (ip: string): void {
        this.addresses.push(ip);
    }

    nameChanged (item: IStationEx): void {
        item.canApply = true;
        this.$forceUpdate();
    }

    applyNameChange (item: IStationEx): void {
        const id = item.station.ident;
        const nm = item.station.name;
        const ad = item.station.master;
        const url = `/api/monitor/set-mp-name?id=${id}&name=${nm}&addr=${ad}`;

        network.apiRequest(url).then(() => {
            setTimeout(() => {
                ttmConfig.load().then(() => {
                    // DONE
                });
            }, 1000);
        });

        item.canApply = false;
        this.$forceUpdate();
    }
}
